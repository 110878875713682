import * as Actions from '../../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from '../RegExValidate'
import { useHistory } from "react-router-dom"
import { MinusSignIcon, PlusSignIcon, RightArrowIcon } from '../SVGBank'
import { encryptID } from '../../HotelPage/hotelServices'
import { environment } from '../../../../Environments/environment'

const OpenEstablishmentBtn_ = ({ establishment, establishmentId, pricelineId, setHotelEstablishmentId, establishmentName, setSwitchContainer,
  divClass = "", establishmentCity = "", establishmentState = "", establishmentCountry = "", titleClass, titleStyle, lang,
  hotel_form_state, ppn_bundle,setHotel,hotelIndex }) => {
  const history = useHistory()
  const city = establishmentCity !== null && establishmentCity !== "" ? "_" + establishmentCity.replace(URL_REPLACE, "_") : ""
  const state = establishmentState !== null && establishmentState !== "" ? "_" + establishmentState.replace(URL_REPLACE, "_") : ""
  const country = establishmentCountry !== null && establishmentCountry !== "" ? "_" + establishmentCountry.replace(URL_REPLACE, "_") : ""
  const hotelType = establishment?.hotelType;
  var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("promo-code") ||  searchParams.get("PROMO-CODE");
    const city_id = searchParams.get("city_id")

  const isMuvHotel = history?.location?.pathname === "/muv-hotel-search";


  const handleClick = () => {
    // setHotelEstablishmentId(pricelineId)
    // setSwitchContainer(member_area_router.establishment)

    sessionStorage.setItem("hotelIndex",hotelIndex)
    const encryptedId = encryptID(pricelineId)

 
    if (hotelType) {
   
      if (hotelType === "hp") {
        const hotelId = establishment?.establishmentID;
        history.push("/muv-hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + hotelId
          + "?city_id=" + hotel_form_state.city_id
          + "&check_in=" + hotel_form_state.check_in
          + "&check_out=" + hotel_form_state.check_out
          + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
          + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
          + "&city_lat=" + (hotel_form_state.city_latitude)
          + "&city_lng=" + (hotel_form_state.city_longitude)
          + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
          + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_"))
          + "&findBy=" + hotelType)
      }
      else if(promoCode){
      
        history.push("/hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + pricelineId
          + "?city_id=" + hotel_form_state.city_id
          + "&check_in=" + hotel_form_state.check_in
          + "&check_out=" + hotel_form_state.check_out
          + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
          + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
          + "&city_lat=" + (hotel_form_state.city_latitude)
          + "&city_lng=" + (hotel_form_state.city_longitude)
          + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
          + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_"))
          + "&promo-code=" + (promoCode))
      }
      else {
        const hotelId = establishment?.establishmentPricelineID;
        const encryptedId = encryptID(hotelId)
        history.push("/hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + encryptedId
          + "?city_id=" + hotel_form_state.city_id
          + "&check_in=" + hotel_form_state.check_in
          + "&check_out=" + hotel_form_state.check_out
          + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
          + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
          + "&city_lat=" + (hotel_form_state.city_latitude)
          + "&city_lng=" + (hotel_form_state.city_longitude)
          + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
          + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
      }

    }
    else {
      if(promoCode){
      
        history.push("/hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + pricelineId
          + "?city_id=" + hotel_form_state.city_id
          + "&check_in=" + hotel_form_state.check_in
          + "&check_out=" + hotel_form_state.check_out
          + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
          + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
          + "&city_lat=" + (hotel_form_state.city_latitude)
          + "&city_lng=" + (hotel_form_state.city_longitude)
          + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
          + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_"))
          + "&promo-code=" + (promoCode))
      }
      else{
        history.push("/hotel-" + establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + encryptedId
        + "?city_id=" + hotel_form_state.city_id
        + "&check_in=" + hotel_form_state.check_in
        + "&check_out=" + hotel_form_state.check_out
        + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
        + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
        + "&city_lat=" + (hotel_form_state.city_latitude)
        + "&city_lng=" + (hotel_form_state.city_longitude)
        + "&ppn_bundle=" + (ppn_bundle ? ppn_bundle : null)
        + "&where=" + (hotel_form_state.where.replace(URL_REPLACE, "_")))
      }
   
    }
  }

  return (
    <div style={{ display: "inline-block", float: "right" }} className={divClass}>
      <div onClick={() => handleClick()}
        style={{
          cursor: "pointer",
          font: "14px/24px Futura Md BT",
          backgroundColor: 'var(--mainGreen)',
          color: "white",
          display: "inline-block",
          width: "150px",
          height: 44,
          padding: 10
        }} >Choose a Room  <RightArrowIcon width={15} height={15} style={{ marginLeft: "8px" }} /></div>

        {!environment.production &&isMuvHotel&&<div style={{color: 'var(--mainBlue)',textAlign:"right",marginTop:2}}>
            {hotelType === "hp"?"Hotel Planner":"Priceline"}
        </div>}
    </div >
  )
}

const mapStateToProps = (state) => ({
  lang: state.Setting.lang,
  hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
  setSwitchContainer: Actions.setSwitchContainer,
  setHotelEstablishmentId: Actions.setHotelEstablishmentId,
}

const OpenEstablishmentBtn = connect(mapStateToProps, mapDispatchToProps)(OpenEstablishmentBtn_)
export default OpenEstablishmentBtn