import { useDispatch, useSelector, connect } from "react-redux";
import React, { useEffect } from 'react';
import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import { RightArrowIcon } from "../Common/SVGBank";
import moment from "moment";
import { landing_router, URL_REPLACE } from "../Common/RegExValidate";

import { getHomeDetails, setTimeLineStatus } from "../../../redux/actions";
import { useHistory } from 'react-router-dom';
import * as Actions from '../../../redux/actions';
import TagManager from 'react-gtm-module';
import { resetFlightPage, updateFlightData } from "../FlightsPage/services/Flights.slice";
import { createTripThunk } from "../FeaturePage/personalizedTrip/personalizedTrip.slice";
import { PersonalizedModalUseStyles } from "../Common/BookingForm/AiPersonalizedTrip/AiPersonalizedTripStyles";
import { HotelFlightSaveModal } from "../FlightsPage/RecapPage/FlightSaveModal";
import { getNearByAirport, saveHotelTimeline } from "../BookingPage/PaymentPage/payment.thunk";

const CongratulationsHotel_ = ({ uuid, booking_bundles, registerMemberAccount, setSwitchProfileContainer }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = PersonalizedModalUseStyles();

    const cartData = useSelector(state => state.HotelBooking.cart.data);
    const firstName = useSelector(state => state.Member.authModal.firstName) || '';

    if (!cartData || !cartData.items || cartData.items.length === 0) {
        return <p>No booking information available.</p>;
    }

    const hotelInfo = cartData.items[0];
    const hotelName = hotelInfo.hotel_name;
    const checkin = moment(hotelInfo.check_in).format('MMM Do');
    const checkout = moment(hotelInfo.check_out).format('MMM Do');
    const bookingNumber = cartData.id;
    const price = hotelInfo.price;
    const currency = hotelInfo.currency;

    const goToBooking = () => {
        if (bookingNumber) {
            const url = `/hotel/booking_details?booking_id=${bookingNumber}`;
            window.open(url);
        }
    };

    return (
        <Grid container style={{ padding: 20 }}>
            <Backdrop className={classes.backdrop} open={false}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Grid item>
                <div>
                    <p style={{ font: '24px/24px Futura Hv BT' }}> Congratulations {firstName},</p>
                </div>

                <div>
                    <p style={{ marginTop: 20, font: '20px/20px Futura Md BT', lineHeight: '30px' }}>
                        Your booking for {hotelName} from {checkin} to {checkout} is complete.
                    </p>

                    {bookingNumber && <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>
                        Your booking reference number is {bookingNumber}.
                    </p>}

                    <p style={{ marginTop: 20, font: '20px/20px Futura LT BT', lineHeight: '30px' }}>
                        You will soon receive a confirmation email with all your booking information.
                    </p>
                </div>
            </Grid>

            <Grid item style={{ marginTop: 60, width: '100%' }}>
                <div>
                    <button className='recapButtons' style={{ background: '#DFDFDF' }} onClick={goToBooking}>
                        See Booking Details
                    </button>
                </div>
            </Grid>
        </Grid>
    );
};

const CongratulationsHotel = connect(null, null)(CongratulationsHotel_);
export default CongratulationsHotel;
