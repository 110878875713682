import moment from 'moment';
import React, { useState, useEffect, memo } from 'react';
import Typist from 'react-typist';
import defaultAvatar from '../../../../assets/images/defaultAvatar.png';
import { CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveHotelTimeline, savePlaceTimeline } from '../../BookingPage/PaymentPage/payment.thunk';
import Loader from 'react-loader-spinner'
import { AIModalUseStyles } from '../BookingForm/AiPersonalizedTrip/AiPersonalizedTripStyles';
import { Backdrop } from '@material-ui/core'
import { saveFlightTimeline } from '../../FlightsPage/services/Flights.thunks';
import { AiTooltip } from '../MemberActions/tooltipActions';
import { useHistory } from 'react-router-dom';
import Markdown from 'markdown-to-jsx'
import { useLocation } from 'react-router-dom';
import LinkWithHover from '../AiChatBox/LinkMarkDownWithHover';


const HotelAiResponses = ({ markMessagesAsRead, createTrip, creating, tripDestination, scrollToBottom, chatResponses, searching, showResults, is_mobile, listRef }) => {
  const [typing, setTyping] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const uuid = useSelector(state => state.Member.authModal.uuid)
  const lang = useSelector(state => state.Setting.lang)
  const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
  const activeTripId = activeTrip?.tripId;
  const history = useHistory()
  const typingRef = React.useRef(null)
  const classes = AIModalUseStyles();
  const timelineStatus = useSelector(state => state.FormBank.TimelineWizard.status)
  const location = useLocation();
  const timelineOpen = timelineStatus && location?.pathname?.includes("destination");

  const dispatch = useDispatch()

  const navigateUrl = (url) => {
    history.push(url)
  }





  const lineTyped = (line, lineIdx) => {
    setTyping(true)
    try {
      typingRef.current.scrollIntoView()
    } catch {

    }
  }


  const messagesEndRef = React.useRef(null)

  const scrollDown = () => {
    if (messagesEndRef?.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "instant" })
    }
  }

  useEffect(() => {
    scrollDown()
  }, [chatResponses, showResults]);

  const onTypingDone = () => {
    setTyping(false);

    if (markMessagesAsRead) {
      setTimeout(() => {
        if (chatResponses.some(group => group.some(message => message?.new))) {
          // Only call if there's a "new" message to mark as read
          markMessagesAsRead();
        }
      }, 400);
    }
  };


  return (
    <div>



      {showResults && <div style={{
        background: "white",
        borderRadius: 20,
        width: is_mobile ? "90%" : timelineOpen ? "60%" : "70%",
        marginLeft: is_mobile ? "5%" : timelineOpen ? "30%" : "15.5%",
        bottom: 80,
        position: "absolute",
        padding: 40,
        paddingLeft: is_mobile ? 20 : 50,
        transition: "all .6s ease-in",
        border: '1px solid lightgrey',
        font: "15px/20px Futura BT",
        maxHeight: is_mobile ? 400 : 600,
        overflowY: "auto",
      }}>




        {chatResponses && chatResponses?.length > 0 && <div>
          {chatResponses.map((responseArray, index) => {


            return (
              <React.Fragment>

                {/* render user messages without animation */}
                <div>
                  {
                    responseArray?.map((message, txtIndex) => {

                      if (message?.new) {

                        return <div key={message + txtIndex}>
                          {/* render other messages with animation */}
                          <Typist key={JSON.stringify(message)} ref={typingRef}
                            cursor={{
                              show: false
                            }} avgTypingDelay={2}
                            stdTypingDelay={10}
                            onLineTyped={lineTyped}
                            onTypingDone={onTypingDone}
                            onCharacterTyped={(c, i) => {
                              if (i > 0) {
                                scrollToBottom()
                              }

                            }}
                          >

                            <ResponseWrapper
                              children={MessageBox(message)} message={message} />

                          </Typist>
                        </div>


                      }
                      return <div key={message + txtIndex}>
                        <ResponseWrapper
                          children={MessageBox(message)} message={message} />
                      </div>



                    })
                  }
                </div>



              </React.Fragment>
            )





          })}

          {searching ?
            <div style={{
              font: "16px/22px Futura Md BT",
              color: "var(--mainGrey)",
              margin: "10px 0px",
              display: "flex"
            }}>
              <img src={'https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'}
                style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="muvBot" />
              <span style={{ paddingTop: 5, font: "16px/22px Futura Md BT", }} className="loadingAnimate">{" "}</span>
            </div>
            : ""}


        </div>}
        <div ref={messagesEndRef} />

        {/* end of list */}
        <div ref={listRef} style={{ height: 30 }} />

        {tripDestination && !typing && <div style={{
          position: "sticky",
          bottom: 0,
          textAlign: "right",
        }}>
          <button className='recapButtons' style={{
            height: 40,
            borderRadius: 10,
            background: '#E52256', color: 'white', font: '14px/14px Futura Lt BT',
          }} onClick={createTrip}>
            {creating ? <CircularProgress
              size={20}
              style={{
                color: "white"
              }} /> : "Generate itinerary from results"}
          </button>
        </div>}

      </div>}



      <Backdrop className={classes.backdrop} open={saving} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div >
  )
};


export default React.memo(HotelAiResponses);




const ResponseWrapper = ({ children, message }) => {
  return (<div style={{ display: "flex", textAlign: "left", marginBottom: "10px", color: "var(--mainGrey)", }}>
    {message?.bot ? <img src={'https://amhqajbken.cloudimg.io/v7/_muv_/muvimages/muv_assets/whitepage/logo.png'}
      style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="muvBot" /> : ""}
    {message?.isUser ? <img src={message?.sender || defaultAvatar}
      style={{ height: 35, width: 35, borderRadius: "50%", marginRight: 10 }} alt="sender" /> : ""}
    <div style={{
      font: message?.isUser ? "16px/22px Futura Md BT" : "inherit",
      whiteSpace: "pre-line",
      marginTop: 5,
      paddingLeft: 0
    }}>

      {children}
    </div>
  </div>)
}

const MessageBox = (message) => {

  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
  const messageText = message?.text;
  const places = message?.places;
  const hotels = message?.hotels;
  const flights = message?.flights;
  const data = message?.data
  const fullJson = message?.fullJson;
  const url = message?.url ? message?.url.split('.com').pop() : null;

  let responseComponent = <></>;

  if (messageText) {
    responseComponent = (
      <div
      >
        <Markdown options={{
          overrides: {
            a: {
              props: {
                // target: "_blank",
                style: {
                  font: "15px/22px Futura Lt BT",
                }
              },
              component: LinkWithHover
            },
            strong: {
              props: {
                style: {
                  font: "15px/22px Futura Md BT",
                }
              },
            },
            li: {
              props: {
                style: {
                  font: "15px/22px Futura Lt BT",
                }
              },
            },
          },
        }}>{messageText}</Markdown>
      </div>
    )
  }

  return responseComponent;
}
