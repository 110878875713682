import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { image_boxing } from "../RegExValidate";
import { environment } from "../../../../Environments/environment";
import { Rating } from "@material-ui/lab";

const AiHoverBox = React.memo(({ establishment }) => {
    const [establishmentImage, setEstablishmentImage] = useState(null);

    // Fetch image only when the component mounts
    const getEstablishmentImage = useCallback(async () => {
        if (!establishment?.generalInfo?.id) return;

        try {
            const url = `${environment.apiUrl}establishment/getSingleimage/${establishment.generalInfo.id}`;
            const response = await Axios.get(url);
            const data = response.data?.results;

            if (data) {
                setEstablishmentImage(image_boxing(data, "260", "130"));
            }
        } catch (error) {
            console.error("Error fetching establishment image:", error);
        }
    }, [establishment]);

    useEffect(() => {
        getEstablishmentImage();
    }, [getEstablishmentImage]);

    if (!establishment) return null; // Prevent rendering if data is missing

    const {
        name,
        about,
        address,
        phone,
        postalCode,
        cityName,
        starRating,
        website,
    } = establishment.generalInfo;

    const { subtypes } = establishment;

    return (
        <div style={{
            width: "300px",
            background: "#fff",
            borderRadius: "10px",
            overflow: "hidden",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.15)",
            fontFamily: "Futura Lt BT, sans-serif"
        }}>
            {/* Image */}
            {establishmentImage && (
                <img
                    src={establishmentImage}
                    alt={name}
                    loading="lazy"
                    style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover"
                    }}
                />
            )}

            {/* Content */}
            <div style={{ padding: "15px" }}>
                {/* City Name & Star Rating in One Line */}
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "5px"
                }}>
                    <p style={{
                        fontSize: "12px",
                        color: "var(--mainGreen)",
                        fontFamily: "Futura Md BT",
                        margin: 0
                    }}>
                        {cityName}
                    </p>

                    {/* Star Rating (Aligned Right) */}
                    {starRating !== undefined && starRating !== "0.0" && (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                        }}>
                            <div style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "Futura Md BT",
                                color: "var(--mainGreen)",
                            }}>
                                {starRating}
                            </div>
                            <Rating
                                readOnly
                                style={{ color: "var(--mainGreen)" }} // Apply custom color
                                size="small"
                                precision={0.5}
                                max={1}
                                value={parseFloat((starRating / 5).toFixed(1))}

                            />
                        </div>
                    )}
                </div>

                {/* Name of Establishment */}
                <h3 style={{
                    marginBottom: "5px",
                    marginTop: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    font: "14px/12px Futura Md BT",
                    color: "var(--frameBlack)",
                }}>
                    {name}
                </h3>

                {/* Description - Scrollable */}
                <div style={{
                    maxHeight: "80px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    fontSize: "12px",
                    lineHeight: "1.6",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    fontFamily: "Futura Lt BT",
                    color: "var(--mainGrey)",
                }}>
                    {about}
                </div>

                {/* Subtypes - Display as a List */}
                {subtypes && subtypes.length > 0 && (
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginBottom: "10px"
                    }}>
                        {subtypes.map((subType) => (
                            <div key={subType.subTypeId} style={{
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "2px 5px",
                                background: "rgba(25, 188, 155, 0.2) 0% 0% no-repeat padding-box",
                                border: "1px solid rgba(25, 188, 155, 0.2)",
                                borderRadius: "12px",
                                color: "#19BC9B",
                                fontFamily: "Futura Lt BT",
                            }}>
                                {subType.subTypeTitle}
                            </div>
                        ))}
                    </div>
                )}

                {/* Add to Trip */}
                {/* <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <button style={{
                        fontSize: "12px",
                        color: "#19a974",
                        border: "none",
                        background: "transparent",
                        fontWeight: "bold",
                        fontFamily: "Futura Md BT",
                        cursor: "pointer"
                    }}>
                        ➕ Add to trip
                    </button>
                </div> */}
            </div>
        </div>
    );
});

export default AiHoverBox;
