import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LeftArrowChevronIcon, RightArrowChevronIcon } from '../Common/SVGBank';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChair,
    faWifi,
    faUtensils,
    faMugHot,
    faSuitcaseRolling,
    faSuitcase,
    faExchangeAlt,
    faTimesCircle,
    faSync
} from '@fortawesome/free-solid-svg-icons';

const getLabelFromOfferType = (type) => {
    switch (type) {
        case 'INCLUDED':
            return 'Included';
        case 'CHARGEABLE':
            return 'for a fee';
        case 'NOT_INCLUDED':
        default:
            return 'Not included';
    }
};

const cabinLabels = {
    economy: 'Economy',
    premium: 'Premium Economy',
    business: 'Business Class',
    first: 'First Class',
};

const FlightUpgradeOptions = ({ fares, itinerary_data, selectFlight }) => {
    const baseFare = fares[0]?.slice_data[0]?.display_total_fare || 0;
    const cabinClass = useSelector(state => state.Flights.flightData.cabinClass);
    const scrollRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);

    const handleScroll = () => {
        if (scrollRef.current) {
            setShowLeftArrow(scrollRef.current.scrollLeft > 0);
        }
    };

    useEffect(() => {
        const el = scrollRef.current;
        if (el) {
            el.addEventListener('scroll', handleScroll);
            return () => el.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const scroll = (direction) => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({
                left: direction === 'left' ? -300 : 300,
                behavior: 'smooth',
            });
        }
    };

    const airlineLogo = itinerary_data?.slice_data[0]?.airline?.logo;

    const selectFare = (fare) => {
        if (selectFlight && fare) {
            selectFlight(fare)
        }

    }
    return (
        <div style={styles.wrapper}>
            {showLeftArrow && (
                <button style={styles.arrowLeft} onClick={() => scroll('left')}>
                    <LeftArrowChevronIcon fill={'#DFDFDF'} height={30} width={30} />
                </button>
            )}

            <div style={styles.scrollContainer} ref={scrollRef}>
                {fares.map((fare, idx) => {
                    const slice = fare.slice_data[0];
                    const name = slice.name;
                    const totalFare = slice.display_total_fare;
                    const symbol = slice.display_symbol;
                    const ancillaries = {};
                    slice.ancillaries.forEach(a => {
                        ancillaries[a.name] = getLabelFromOfferType(a.offer_type);
                    });
                    const upgradeAmount = totalFare - baseFare;

                    return (
                        <div key={idx} style={{ ...styles.card, border: upgradeAmount === 0 ? `2px solid #19BC9B` : '1px solid #DFDFDF' }}>
                            {airlineLogo && (
                                <img src={airlineLogo} alt="Airline Logo" style={{ height: 20, marginBottom: 10 }} />
                            )}
                            <h3 style={{ font: "16px/16px Futura Hv BT" }}>{name + ' Fare'}</h3>
                            {/* <p><span style={{ font: "12px/16px Futura Hv BT" }}>Cabin:</span> {cabinLabels[cabinClass] || 'Unknown'}</p> */}

                            <div style={styles.section}><strong>Comfort:</strong>
                                {ancillaries['Seat Selection'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faChair} /></span> Seat Selection: <span style={styles.label}>{ancillaries['Seat Selection']}</span></div>}
                                {ancillaries['Wifi'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faWifi} /></span> Wifi: <span style={styles.label}>{ancillaries['Wifi']}</span></div>}
                                {ancillaries['Meals and Beverages'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faUtensils} /></span> Meals and Beverages: <span style={styles.label}>{ancillaries['Meals and Beverages']}</span></div>}
                            </div>

                            <div style={styles.section}><strong>Baggage:</strong>
                                {ancillaries['Carry-on Bag'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faSuitcaseRolling} /></span> Carry-on Bag: <span style={styles.label}>{ancillaries['Carry-on Bag']}</span></div>}
                                {ancillaries['Checked Bag'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faSuitcase} /></span> Checked Bag: <span style={styles.label}>{ancillaries['Checked Bag']}</span></div>}
                            </div>

                            <div style={styles.section}><strong>Flexibility:</strong>
                                {ancillaries['Changes'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faSync} /></span> Changes: <span style={styles.label}>{ancillaries['Changes']}</span></div>}
                                {ancillaries['Cancellation'] && <div style={styles.item}><span style={styles.icon}><FontAwesomeIcon icon={faTimesCircle} /></span> Cancellation: <span style={styles.label}>{ancillaries['Cancellation']}</span></div>}
                            </div>

                            <div style={styles.footer}>
                                {upgradeAmount === 0 ? (
                                    <>
                                        <h3 style={styles.price}>{symbol}{totalFare.toFixed(2)}</h3>
                                        <button onClick={() => selectFare(fare)} style={styles.selectBtn}>Select</button>
                                    </>
                                ) : (
                                    <>
                                        <h4 style={styles.priceUpgrade}>+{symbol}{upgradeAmount.toFixed(2)}</h4>
                                        <button onClick={() => selectFare(fare)} style={styles.upgradeBtn}>Upgrade</button>
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            <button style={styles.arrowRight} onClick={() => scroll('right')}>
                <RightArrowChevronIcon height={30} width={30} fill={'#DFDFDF'} />
            </button>
        </div>
    );
};

const styles = {
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    scrollContainer: {
        display: 'flex',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        padding: '20px 0px',
        paddingRight: 20,
        gap: '20px',
        width: '100%',
    },
    card: {
        flex: '0 0 auto',
        minWidth: '350px',
        border: '1px solid #DFDFDF',
        padding: 20,
        backgroundColor: '#fff',
        font: '14px/20px Futura Lt BT',
    },
    section: {
        marginTop: '16px',
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
    },
    label: {
        font: '14px/20px Futura Md BT',
    },
    price: {
        font: '16px/20px Futura Hv BT',
        color: 'var(--mainGreen)'
    },
    priceUpgrade: {
        font: '16px/20px Futura Hv BT',
        color: 'var(--mainBlue)'
    },
    icon: {
        marginRight: '10px',
        width: '30px',
        display: 'inline-block',
        textAlign: 'left',
    },
    footer: {
        marginTop: '20px',
        paddingTop: 14,
        textAlign: 'center',
        borderTop: '1px solid #DFDFDF',
    },
    selectBtn: {
        padding: '10px 20px',
        backgroundColor: '#19BC9B',
        border: 'none',
        color: '#fff',
        borderRadius: '20px',
        cursor: 'pointer',
        font: '14px/20px Futura Md BT',
    },
    upgradeBtn: {
        padding: '10px 20px',
        backgroundColor: 'var(--mainBlue)',
        font: '14px/20px Futura Md BT',
        border: 'none',
        color: '#fff',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    arrowLeft: {
        position: 'absolute',
        left: 0,
        zIndex: 1,
        background: 'none',
        border: 'none',
        fontSize: '2rem',
        cursor: 'pointer',
        padding: '10px',
    },
    arrowRight: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
        background: 'none',
        border: 'none',
        fontSize: '2rem',
        cursor: 'pointer',
        padding: '10px',
    },
};

export default FlightUpgradeOptions;