import { Grid } from "@material-ui/core"
import moment from "moment"
import React from 'react'
import { lookUpHotelThunk,CancelHotelThunk, getHoteImages } from '../BookingPage/PaymentPage/payment.thunk';
import { convertObjArray } from "./MuvManagementList"
import { tConv24 } from "../../../utility/utility";



export const HotelDetails = ({ hotel, imageUrl }) => {
    const [image,setImage]= React.useState()
    const hotelName = hotel?.hotel_name;
    const rating = hotel?.star_rating;
    const phone = hotel?.phone;
    const address = hotel?.address;
    const mapAddress = address?.address_line_one + " " + address?.city_name;

    const mapLink = address && address?.address_line_one ? "http://maps.google.com/?q=" + mapAddress?.replace(" ", "+") : null;

    React.useEffect(()=>{
        const getImages = async ()=>{
            const hotelIds = [hotel?.hotel_id]?.toString();
            const images = await getHoteImages(hotelIds);
            if(images?.length > 0){
                setImage(images[0])
            }
        }

        if(hotel?.hotel_id){
            getImages();
        }

    },[hotel])


    return (
        <Grid container >
            <div style={{ padding: "20px 12%", width: "100%", clear: "both" }}>

                {image && <div style={{ float: "left", paddingRight: 17 }}>
                    <img alt="hotelImage" src={image} height={182} width={274} />
                </div>}
                <div style={{ paddingTop: 15, float: "left" }}>
                    <div
                        style={{ color: '#000000', font: "20px/20px Futura Md BT", paddingBottom: 10 }}
                    >
                        {hotelName}
                    </div>

                    <div
                        style={{ color: '#707070', font: "16px/16px Futura Lt BT", marginBottom: 10 }}
                    >
                        <span style={{ color: "#FF0000", font: "16px/16px Futura Md BT" }}>{rating}/5</span>
                    </div>

                    <div
                        style={{ color: '#707070', font: "14px/14px Futura Md BT", marginBottom: 10, lineHeight: "22px" }}
                    >
                        {address?.address_line_one},<br />
                        {address?.city_name}, {address?.state_code},  {address?.zip},  {address?.country_code},<br />
                        Tel: {phone}
                    </div>

                    {mapLink && <a target="_blank" rel="noopener noreferrer" href={mapLink} style={{
                        cursor: "pointer", color: '#0D3A7D', font: "14px/14px Futura Md BT",
                        marginBottom: 10, lineHeight: "22px"
                    }}> View on map</a>}
                </div>


            </div>


        </Grid>

    )
}


export const HotelBookingStatus = ({ hotelName, booking_status }) => {
    return (
        <div style={{ width: "100%" }}>
            <div style={{ color: '#19BC9B', font: "25px/30px Futura Hv BT", padding: "18px 12%", marginBottom: 15, lineHeight: "40px" }}>
                Your booking at the {hotelName} is {booking_status}
            </div>
        </div>
    )
}


export const HotelConfirmationDetails = ({ itinerary, itinerary_details, hotel,is_mobile }) => {
    const confirmationId = itinerary?.id;
    const check_in = moment(itinerary?.check_in).format('ddd, MMM D')
    const check_out = moment(itinerary?.check_out).format('ddd, MMM D')
    const num_rooms = itinerary_details?.num_rooms;
    const num_nights = itinerary_details?.num_nights;
    const check_in_time = tConv24(hotel?.check_in_time);
    const check_out_time = tConv24(hotel?.check_out_time);


    return (
        <div style={{ width: "100%" }}>
            <div
                style={{ color: '#707070', font: "20px/20px Futura Lt BT", padding: "10px 12%", marginTop: 30 }}
            >
                Your booking is guaranteed and all paid for.
            </div>
            <div style={{ background: "#E8E8E8", color: '#0B91D8', font: "22px/22px Futura Md BT", padding: "10px 12%", marginBottom: 15, lineHeight: "40px" }}>
                Trip number
                <span style={{ float: "right", paddingTop: 10, color: "#0D3A7D", font: "18px/18px Futura Md BT" }}>{confirmationId}</span>
            </div>

            <div style={{ padding: "10px 12%" }}>

                <div style={{
                    color: '#707070', font: "18px/18px Futura Md BT",
                    lineHeight: "25px", width: "30%", textAlign: "left", float: "left"
                }}>
                    Check in
                    <br />
                    <span style={{ color: '#0D3A7D', font:is_mobile?  "16px/22px Futura Md BT" : "22px/22px Futura Md BT" }}>
                        {check_in}
                    </span>
                    <br />
                    {check_in_time}

                </div>

                <div style={{
                    color: '#707070', font: "18px/18px Futura Md BT",
                    lineHeight: "25px", width: "30%", textAlign: "right", float: "right",
                }}>
                    Check out
                    <br />
                    <span style={{ color: '#0D3A7D', font:is_mobile?  "16px/22px Futura Md BT": "22px/22px Futura Md BT" }}>
                        {check_out}
                    </span>
                    <br />
                    {check_out_time}

                </div>

                <div style={{
                    clear: 'both', paddingTop: 30,
                    color: '#707070', font: "18px/18px Futura Md BT",
                    lineHeight: "25px"
                }}>
                    {num_rooms} rooms, {num_nights} nights


                </div>
            </div>
            <hr />
        </div>
    )
}





export const HotelBookingSupport = ({ lookUpData }) => {
    const policy_data = lookUpData?.important_information?.policy_data
    const policyList = convertObjArray(policy_data);
    return (
        <div style={{
            background: "#E8E8E8",
            color: "#707070", marginLeft: "8%", marginRight: "8%",
            marginBottom: 15, marginTop: 25, width: '100%', padding: "40px 4%"
        }}>

            <div
                style={{ color: '#707070', font: "16px/16px Futura Md BT", paddingBottom: 40 }}
            >
                Important Info
            </div>

            {policyList && policyList.length > 0 &&

                policyList.map((policy, index) => {
                    const paragraphs = convertObjArray(policy?.paragraph_data);
                    return (
                        <div key={index + "policy"} style={{ paddingBottom: 20 }}>
                            <div
                                style={{ color: '#707070', font: "16px/16px Futura Md BT", paddingBottom: 15 }}
                            >
                                {policy?.title}
                            </div>

                            {
                                paragraphs && paragraphs.length > 0 &&

                                paragraphs.map((paragraph, pIndex) => {
                                    return (
                                        <div key={pIndex + "paragraph"}
                                            dangerouslySetInnerHTML={{ __html: paragraph }}
                                            style={{ color: '#707070', font: "16px/16px Futura Lt BT", paddingBottom: 15 }}
                                        />
                                    )
                                })
                            }

                        </div>
                    )
                })

            }
            <div
                style={{ color: '#707070', font: "14px/16px Futura Md BT", paddingBottom: 10 }}
            >
                For support, please call at <a href="tel:+1-877-477-7441" type="tel">1-877-477-7441</a>
            </div>




        </div>
    )
}



