import * as Actions from '../../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../../Common/RegExValidate"
import { connect } from 'react-redux'
import { member_area_router } from '../RegExValidate'
import { useHistory } from "react-router-dom"

const EstablishmentName_ = ({ establishmentId, setEstablishmentId, establishmentName, setSwitchContainer, divClass = "", establishmentCity = "", establishmentState = "", establishmentCountry = "", titleClass, titleStyle, lang,isCustom }) => {
  const history = useHistory()
  const city = establishmentCity && establishmentCity !== "" ? "-" + establishmentCity.replace(URL_REPLACE, "-") : ""
  const state =establishmentState &&  establishmentState !== "" ? "-" + establishmentState.replace(URL_REPLACE, "-") : ""
  const country = establishmentCountry &&  establishmentCountry !== "" ? "-" + establishmentCountry.replace(URL_REPLACE, "-") : ""
  
  const handleClick = () => {
    // setEstablishmentId(establishmentId)
    // setSwitchContainer(member_area_router.establishment)
    const url = "/establishment/" + establishmentName.replace(URL_REPLACE, "-") + city + state + country + "/" + establishmentId;
    window.open(url.toLowerCase(), "_blank")
    // history.push(url.toLowerCase())
  }
  return (
    <div  className={divClass}>
      <div style={titleStyle} className={titleClass} onClick={() => isCustom !== "1" && handleClick()}>
        {establishmentName}
      </div>
    </div >
  )
}

const mapStateToProps = (state) => ({
  lang: state.Setting.lang,
})

const mapDispatchToProps = {
  setSwitchContainer: Actions.setSwitchContainer,
  setEstablishmentId: Actions.setEstablishmentId,
}

const EstablishmentName = connect(mapStateToProps, mapDispatchToProps)(EstablishmentName_)
export default EstablishmentName