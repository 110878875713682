import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PlaceHolderImage from '../../../../assets/images/placeholderImage.jpeg';
import { TextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { GetAiResponse, GetAiSuggestions } from './AiChatBox.services';
import moment from 'moment';
import AiResponses from './AiResponses';
import { createTripThunk } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice';
import { URL_REPLACE } from '../RegExValidate';
import { useHistory } from "react-router-dom"
import { encryptID } from '../../HotelPage/hotelServices';
import { useLocation } from 'react-router-dom';
import { formattedHtml } from '../HotelChatBox/HotelChatBox';
import MuvGptLogo from './muvGptLogo';
import { toggleAiResults, updateAiResponses, updateAiSearching, updateTripDest } from './AiChatBoxReducer';
import { deleteCookie, getCookie, setCookie } from './helperAi';

const AiChatBoxEmbedded_ = ({ timelineStatus, lang, getMemberAreaMuverInfo, uuid, activeTab, homepageSliderImage, setBookingFormActiveTab, homepageGeneralInfo, logo_width, is_mobile, bodyClientWidth, app_menu_height }) => {

    React.useEffect(() => {
        if (uuid) {
            getMemberAreaMuverInfo(uuid)
        }
    }, [uuid])



    const history = useHistory()

    const [places, setPlaces] = React.useState([]);
    const [message, setMessage] = React.useState("");

    const listRef = useRef()

    const member = useSelector(state => state.Member);
    const avatarUrl = member?.memberArea?.memberMuver?.muverAvatar?.fullUrl;
    const [sessionId, setSessionId] = React.useState("");
    const [typeIndex, setTypeIndex] = React.useState(0)
    const [creating, setCreating] = React.useState(false)
    const dispatch = useDispatch()
    const location = useLocation();
    const aiBoxData = useSelector(state => state.AiChatBox);
    const chatResponses = aiBoxData?.responses;
    const showResults = aiBoxData?.showResults;
    const searching = aiBoxData?.searching;
    const tripDest = aiBoxData?.tripDest;

    const timelineOpen = timelineStatus && location?.pathname !== "/" && !location?.pathname?.includes("muver");

    const handleMessage = React.useCallback((event) => {
        const val = event.target.value
        setMessage(val)
    }, []);


    const chatBotRef = React.useRef(null);

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (chatBotRef.current && !chatBotRef.current.contains(event.target)) {
                setResults(false)
                // You can perform any action here when a click happens outside
            }
        };

        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


   React.useEffect(() => {
          let messages = getCookie("chatMessages");

          if (messages) {
            messages = JSON?.parse(messages);
              if (messages !== chatResponses) {
                  dispatch(updateAiResponses([...messages])) ///prefill
              }
          }
      }, []);
  
  
      const saveToLocal = (responses) => {
          setCookie("chatMessages", JSON.stringify(responses));
      }

    const updateResponses = (data) => {
        dispatch(updateAiResponses([...data]))
        saveToLocal([...data])
    }





    const sendMessage = async (event) => {
        if (event?.key === 'Enter' && message?.length > 0) {
            pushMessage()
        }

    }

    const pushMessage = async () => {
        if (message?.length > 0) {
            setSearching(true)

            let newMessages = [];


            let combineResponses = [...chatResponses];

            combineResponses.push([{
                sender: avatarUrl,
                text: message,
                isUser: true

            }])

            updateResponses(combineResponses);

            if (!showResults) {
                setResults(true)
            }
            ////push user message first


            //push suggestion
            const dataMessage = message;
            const result = await GetAiSuggestions({ message: dataMessage, member_id: uuid, muvgpt_session_id: sessionId });

            const resultSessionId = result?.result?.muvgpt_session_id;
            const dest = result?.result?.tripAI;
      
            if(dest?.destination?.id && dest?.destination?.establishment_ids?.length > 0  ){
                setDestination(dest)
            }



            setSessionId(resultSessionId)
            const messageResponse = result?.result?.prompt_response;
            const html = formattedHtml(messageResponse)


            newMessages.push({
                bot: true,
                text: html ?? "I am not sure I get your request, can you be more precise?",
                new:true
            })



            ///for responses



            combineResponses.push(newMessages)

            updateResponses(combineResponses)



            setSearching(false)
            setMessage("")
        }

    }


    const scrollToBottom = () => {
        try {
            listRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
        catch {

        }
    }


    const toggleResults = () => {
        setResults(!showResults)
    }

    const setResults = (data) => {
        dispatch(toggleAiResults(data))
    }

    const setSearching = (data) => {
        dispatch(updateAiSearching(data))
    }


    const setDestination = (data) => {
        dispatch(updateTripDest(data))
    }






    const createTrip = React.useCallback(async () => {
        const destination = tripDest?.muv_destination ? [{
            ...tripDest?.muv_destination,
            duration: tripDest?.trip_duration || 3
        }] : null;

        if (destination) {
            setCreating(true)
            const trip = {
                destinations: destination,
                lang: lang,
                uuid: uuid || "TMPUUID",
                tripId: ""

            }
            const newTrip = await dispatch(createTripThunk(trip));
            const addedTrip = newTrip.payload;
            if (addedTrip) {
                const encryptedId = encryptID(addedTrip?.tripID)


                const url = "/trip/" + addedTrip?.tripName.replace(URL_REPLACE, "-") + "/" + encryptedId;
                history.push(url.toLowerCase())



                setCreating(false)
            }
            else {
                setCreating(false)
            }

            setTimeout(() => {
                setCreating(false)
            }, 8000);
        }

    }, [tripDest])


    const AIReponsesMemo = React.useMemo(() => {
        return (
            <AiResponses createTrip={createTrip}
                creating={creating} chatResponses={chatResponses} searching={searching} tripDestination={tripDest}
                showResults={showResults} scrollToBottom={scrollToBottom} is_mobile={is_mobile} listRef={listRef} />
        )
    }, [searching, creating, tripDest, showResults, is_mobile, listRef])




    React.useEffect(() => {
        setTimeout(() => {
            scrollToBottom()
        }, 50);
    }, [chatResponses])


    const handleArrowClick = () => {
        toggleResults()
        pushMessage()

        if (showResults) {

            let combineResponses = [...chatResponses];

            combineResponses.push([{
                text: "",

            }])

            updateResponses(combineResponses);

        }
    }

    return (

        <div >


            <div style={{
                marginTop: 30,
                width: is_mobile ? "95%" : "100%", position: "relative",
            }}>
                <div style={{ marginTop: 10, position: "absolute", zIndex: 10, left: 15 }} >
                    <MuvGptLogo />
                </div>
                <TextField
                    onKeyPress={sendMessage}
                    disabled={searching}
                    value={message}
                    style={{
                        background: "white",
                        borderRadius: 50,
                        width: "100%",
                        marginLeft: 0,
                        padding: 10,
                        paddingLeft: 120,
                        paddingRight: 70,
                        border: '1px solid lightgrey',
                        font: "18px/20px Futura Lt BT",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    placeholder={'Ask anything for you next trip: find me a flight,  where can I stay, what should I visit, Just be precise and mûvGPT will find you the best places and deals.'}
                    id='aiTextBox'
                    InputProps={{ disableUnderline: true }}
                    onChange={handleMessage}
                />
                <div onClick={handleArrowClick} style={{
                    background: "var(--mainGreen)", cursor: "pointer", position: "absolute",

                    right: -2,
                    top: 0,
                    paddingRight: 25,
                    paddingLeft: 25,
                    paddingTop: 11,
                    paddingBottom: 11,
                    borderRadius: 50,

                }}>
                    <FontAwesomeIcon size="1x" icon={faSearch} style={{ color: 'white', marginRight: "5px" }} />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    uuid: state.Member.authModal.uuid,
    logo_width: state.Setting.appMenu.logo.width,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    lang: state.Setting.lang,
    timelineStatus: state.FormBank.TimelineWizard.status,
    app_menu_height: state.Setting.appMenu.app_menu_height,
    homepageSliderImage: state.Home.homepage_slider_image,
    homepageGeneralInfo: state.Home.homepage_general_info,
    is_mobile: state.Setting.is_mobile,
    activeTab: state.FormBank.BookingForm.activeTab,
})

const mapDispatchToProps = {
    getMemberAreaMuverInfo: Actions.getMemberAreaMuverInfo,
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
}

const AiChatBoxEmbedded = connect(mapStateToProps, mapDispatchToProps)(AiChatBoxEmbedded_)
export default React.memo(AiChatBoxEmbedded)
