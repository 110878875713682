import { createSlice, current } from '@reduxjs/toolkit'


const initialState = {
   responses:[],
   showResults:false,
   searching:false,
   tripDest:null
}

export const AiChatBoxSlice = createSlice({
    name: 'AiChatBox',
    initialState: initialState,
    reducers: {
        toggleAiResults: (state, action) => {
         state.showResults =  action.payload
        },
        updateAiResponses:(state,action)=>{
            state.responses = action.payload;
        },
        updateAiSearching:(state,action)=>{
            state.searching = action.payload;
        },
        updateTripDest:(state,action)=>{
            state.tripDest = action.payload;
        },
       
    },
    extraReducers: {
       
        
    },
    
})

export const {updateTripDest,toggleAiResults,updateAiSearching,updateAiResponses} = AiChatBoxSlice.actions;
export const AiChatBoxReducer = AiChatBoxSlice.reducer;