import * as Actions from '../../../redux/actions'

import {
    BusIcon,
    FlightShapeIcon,
    HotelDuotoneIcon,
    MapDuotoneIcon,
    SubwayIcon,
} from '../Common/SVGBank'

import PriceLineBookingFormElement from '../Common/BookingForm/PriceLineBookingFormElement'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import ScrollEventListener from '../Common/ScrollEventListener'
import { TimesIcon } from "../Common/SVGBank"
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import { environment } from '../../../Environments/environment'
import AiChatBoxEmbedded from '../Common/AiChatBox/AiChatBoxEmbedded'

// import {
//     member_area_router,
// } from "../Common/RegExValidate"
// import {
//     useHistory
// } from "react-router-dom"

/* BookingFormHeader component */
export const BookingFormHeader = connect((state) => ({
    logo_width: state.Setting.appMenu.logo.width,
    logo_height: state.Setting.appMenu.logo.height,
    lang: state.Setting.lang,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    textColor: state.Setting.appMenu.textColor,
    activeTab: state.FormBank.BookingForm.activeTab,
    is_mobile: state.Setting.is_mobile,
    pageYOffset: state.Setting.screenVertical.pageYOffset,
    backGroundColor: state.Setting.appMenu.backGroundColor,
    backGroundColorMobile: state.Setting.appMenu.backGroundColorMobile,
    open: state.FormBank.BookingForm.status,

    // switch_container: state.Member.switch_container,
}), {
    setBookingFormActiveTab: Actions.setBookingFormActiveTab,
    setBookingFormStatus: Actions.setBookingFormStatus,
})(({
    setBookingFormActiveTab,
    setBookingFormStatus,
    bodyClientWidth,
    textColor,
    pageYOffset,
    // lang,
    is_mobile,
    activeTab,
    logo_width,
    logo_height,
    backGroundColor,
    backGroundColorMobile,
    open,
    // activeTrip,
    // switch_container,
}) => {

    const anchorRef = React.useRef(null)
    const prevOpen = React.useRef(open)

    // const handleToggle = () => {
    //     setBookingFormStatus(!prevOpen.current)
    // }

    const handleClose = event => {

        if ((anchorRef.current && anchorRef.current.contains(event.target))
            || event.target.className === "open_header_booking_form") {
            setBookingFormStatus(false)
            return
        }

    }
    const handleForceClose = event => {
        setBookingFormStatus(false)
    }
    const handleForceOpen = event => {
        setBookingFormStatus(true)
    }


    React.useEffect(() => {
        return () => {
            setBookingFormStatus(false)
        };
    }, [])



    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }
        prevOpen.current = open
    }, [open])
    //link to destiantion
    // const history = useHistory()
    // React.useEffect(() => {
    //     const activeTripId = activeTrip.tripId
    //     const activeTripDestinations = activeTrip.tripDestinations
    //     console.log(activeTripId)
    //     console.log(activeTripDestinations)
    //     const destination_id = activeTripDestinations !== undefined && activeTripDestinations.length > 0 && activeTripDestinations[0] !== undefined ? activeTripDestinations[0].id : ""
    //     if ((activeTripId !== "" && destination_id !== "")) {
    //         history.push("/" + lang + "/destination/" + destination_id)
    //     }
    // }, [activeTrip])
    const handleLinkAction = (param) => {
        setBookingFormActiveTab(param)
    }
    return (
        <div >
            {!is_mobile && <ClickAwayListener onClickAway={() => null} >
                <div ref={anchorRef}>

                    <div className="ml-auto row" style={{
                        font: "16px / 36px Futura Md BT",
                        letterSpacing: "0px",
                        cursor: "pointer",
                        color: textColor,
                    }}

                        onClick={handleForceOpen}
                    >

                        <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('planNewTrip')}>{"Plan a trip"}</div>
                        <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('plan a trip')}>{"Explore"}</div>
                        <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('hotel')}>{"Hotel"}</div>
                        <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('flight')}>{"Flight"}</div>
                        {/* <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('train')}>{"Train"}</div>
                        <div style={{ marginLeft: "50px", font: "16px/32px Futura Hv BT" }} onClick={() => handleLinkAction('bus')}>{"Bus"}</div> */}
                    </div>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined}
                        placement={"bottom-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <div style={{
                                    width: (bodyClientWidth - 570) + "px",
                                    minWidth: 950,
                                    height: "auto",
                                    backgroundColor: "#e8e8e8",
                                    padding: "30px",
                                    position: "relative",
                                    marginTop: pageYOffset === 0 ? "44px" : "14px",
                                }}
                                    className="background-img-row">
                                    <PriceLineBookingFormElement isHeader={"is_header"} />
                                    <div style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={handleForceClose}>
                                        <TimesIcon width={15} height={15} />
                                    </div>
                                </div>
                            </Grow>
                        )}
                    </Popper>

                </div>
            </ClickAwayListener>}
            {is_mobile &&
                <ClickAwayListener onClickAway={handleClose}>
                    <div ref={anchorRef}>
                        <div
                            style={{ outline: "0px", height: logo_height, backgroundColor: backGroundColorMobile || backGroundColor, display: "", }}

                            onClick={handleForceOpen}>
                            <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile || backGroundColor, }} >
                                <div style={{ outline: "0" }}><MapDuotoneIcon fill={textColor} /></div>
                                <div style={{
                                    font: "8px/28px Futura Lt BT",
                                    color: textColor,
                                    outline: "0"
                                }}
                                    onClick={() => handleLinkAction('plan a trip')}
                                >Explore</div>
                            </div>
                            <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile || backGroundColor, }} >
                                <div style={{ outline: "0" }}><HotelDuotoneIcon fill={textColor} /></div>
                                <div style={{
                                    font: "8px/28px Futura Lt BT",
                                    color: textColor,
                                    outline: "0"
                                }}
                                    onClick={() => handleLinkAction('hotel')}
                                >Hotel</div>
                            </div>
                            <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColorMobile || backGroundColor, }} >
                                <div style={{ outline: "0" }}><FlightShapeIcon fill={textColor} /></div>
                                <div style={{
                                    font: "8px/28px Futura Lt BT",
                                    color: textColor,
                                    outline: "0"
                                }}
                                    onClick={() => handleLinkAction('flight')}
                                >Flight</div>
                            </div>
                            {/* <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColor, }} >
                                <div style={{ outline: "0" }}><SubwayIcon fill={textColor} /></div>
                                <div style={{
                                    font: "8px/28px Futura Lt BT",
                                    color: textColor,
                                    outline: "0"
                                }}
                                    onClick={() => handleLinkAction('train')}
                                >Train</div>
                            </div>
                            <div style={{ width: logo_width, textAlign: "center", paddingTop: "7px", paddingBottom: "7px", outline: "0", backgroundColor: backGroundColor, }} >
                                <div style={{ outline: "0" }}><BusIcon fill={textColor} /></div>
                                <div style={{
                                    font: "8px/28px Futura Lt BT",
                                    color: textColor,
                                    outline: "0"
                                }}
                                    onClick={() => handleLinkAction('bus')}
                                >Bus</div>
                            </div> */}
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 1000,
                            }}>
                            <div style={{
                                width: "100%",
                                height: "auto",
                                backgroundColor: "#e8e8e8",
                                padding: "20px",
                                position: "relative",
                                marginTop: "0px",
                                top: "25%"
                            }}
                                className="background-img-row">

                                <PriceLineBookingFormElement />

                                   

                                <div style={{ position: "absolute", top: "2px", right: "7px", cursor: "pointer" }} onClick={handleForceClose}>
                                    <TimesIcon width={15} height={15} />
                                </div>
                            </div>

                        </Modal>
                    </div>
                </ClickAwayListener>}
            {<ScrollEventListener />}
        </div>
    )
})