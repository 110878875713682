import React, { useState, useRef, useEffect, useCallback } from "react";
import { GetAIEstablishment } from "./AiChatBox.services";
import AiHoverBox from "./AiHoverBox";

export const LinkWithHover = ({ children, ...props }) => {
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [establishment, setEstablishment] = useState(null);
    const [position, setPosition] = useState("top"); // Default position (inverse logic)
    const parentRef = useRef(null);

    const loadEst = useCallback(async (id) => {
        if (!id) return;
        setLoading(true);
        try {
            const result = await GetAIEstablishment(id);
            setEstablishment(result);
        } catch (error) {
            console.error("Error fetching establishment:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const onEnter = async () => {
        try {
            const { href } = props;
            if (typeof href !== "string" || !href.includes("establishment")) return;

            const id = href.split("/").pop();
            if (!id) return;

            setHovered(true);
            await loadEst(id);

            // Adjust position based on available space
            if (parentRef.current) {
                const rect = parentRef.current.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;

                // Invert the logic: If more space above, show below. If more space below, show above.
                setPosition(spaceAbove > spaceBelow ? "bottom" : "top");
            }
        } catch (error) {
            console.error("Error processing hover event:", error);
        }
    };

    const onLeave = () => {
        setHovered(false);
    };

    return (
        <a
            ref={parentRef}
            {...props}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            style={{ position: "relative" }}
        >
            {children}

            {hovered && (
                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        [position]: "100%", // Sets either 'top' or 'bottom'
                        // marginTop: position === "top" ? "5px" : "0",
                        // marginBottom: position === "bottom" ? "5px" : "0",
                        padding: "8px",
                        borderRadius: "4px",
                        whiteSpace: "nowrap",
                        opacity: 1,
                        transition: "opacity 0.2s ease-in-out",
                        zIndex: 300,
                    }}
                >
                    {establishment && <AiHoverBox establishment={establishment} />}
                </div>
            )}
        </a>
    );
};

export default LinkWithHover;
