import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import AreYouMuver from "./AreYouMuver/AreYouMuver"
import BlogList from "./Blog/BlogSlider"
import BookingFormSliderContainer from '../Common/BookingForm/BookingFormSliderContainer'
import BucketList from "./BucketList/BucketList"
import { Discover } from "../TripPage/Discover.jsx"
import { FooterWhite } from "../Footer/FooterWhite.jsx"
import Header from '../Header/Header.jsx'
import { HeaderMobile } from '../Header/HeaderMobile'
import Influencer from "./Influencer/Influencer.jsx"
import MuvInPress from "./MuvInPress/MuvInPress"
import { PropTypes } from 'prop-types'
import ScrollToTop from '../Common/ScrollToTop'
import StartPlanning from './StartPlanning/StartPlanning'
import SuggestedDestination from "./SuggestedDestination/suggestedDestination"
import Trending from "./Trending/Trending"
import { connect } from 'react-redux'
import { TrackEvent } from '../../../utility/utility'
import WhyJoin from './WhyJoin/WhyJoin'
import AiChatBox from '../Common/AiChatBox/AiChatBox'
import { environment } from '../../../Environments/environment'

class HomePageContainer_ extends Component {
	handleScroll = () => {
		const { setAppMenuBookingButtonStatus, settAppMenuBackGroundColor, setBookingFormStatus } = this.props;
		const scrollPosition = window.scrollY || document.documentElement.scrollTop; // Fallback for scrollY
		const rgbaValue = Math.floor(scrollPosition / 4) > 99 ? 1 : Math.floor(scrollPosition / 4) / 100;
	  
		if (scrollPosition < 1100) {
		  settAppMenuBackGroundColor(`rgba(25, 25, 25, ${rgbaValue})`);

		  
		}
	  
		if (scrollPosition < 400) {
		  setAppMenuBookingButtonStatus(false);
		  setBookingFormStatus(false);
		} else {

		  setAppMenuBookingButtonStatus(true);
		  settAppMenuBackGroundColor(`rgba(25, 25, 25, 1)`);
		}

	
	  }
	  
	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll)
		TrackEvent('HOME', "Home page visit", "Home page")
	}
	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll)
	}
	render() {
		const { homepage, homepageMuverStyle, homepageMuverPress, is_mobile, homepageBucketList, } = this.props


		let padding_l = "8.5"
		let padding_r = "8.5"
		let right_outline = "-18.5"
		if (!isNaN(is_mobile)) {
			padding_l = is_mobile ? "1" : "8.5"
			padding_r = is_mobile ? "1" : "8.5"
			right_outline = is_mobile ? "1" : "-18.5"
		}
		return (
			<React.Fragment>
				<div className="homePage">
					{!is_mobile ? <Header bg_color={"rgba(25,25,25, 0)"} text_color={"var(--mainWhite)"} homePage /> : <HeaderMobile bg_color={"transparent"} text_color={"var(--mainWhite)"} useTransparent />}
					< BookingFormSliderContainer />
					{
						homepage.suggestedDestinations !== undefined && homepage.suggestedDestinations.length > 0 && <div style={{
							marginLeft: padding_l + "%", marginRight: padding_r + "%",
						}}>
							<SuggestedDestination />
							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" />}
						</div>

					}

					{
						homepageBucketList !== undefined && homepageBucketList.length > 0 && <div style={{ position: "relative", left: padding_l + "%", right: right_outline + "%", marginTop: is_mobile ? "0px" : "150px" }}>
							<BucketList />
							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" />}
						</div>
					}

					{
						homepageMuverStyle.result && homepageMuverStyle.result.length > 0 && <div style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%", marginTop: is_mobile ? "0px" : "90px" }}>
							<AreYouMuver />
							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" />}
						</div>
					}

					{
						homepage.trendingTrips && homepage.trendingTrips.length > 0 && <div className="card-section" style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%", marginTop: is_mobile ? "0px" : "150px" }}>
							<Trending />
						</div>
					}
					<StartPlanning />
					{
						homepage.influencers && homepage.influencers.length > 0 && <div style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%", height: "500px" }}>
							<Influencer />
							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" style={{ marginTop: "250px" }} />}
						</div>
					}
					<WhyJoin />

					{/* {
						homepageMuverPress.result && homepageMuverPress.result.length > 0 && <div className="muvInPress" style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%" }}>
							<MuvInPress />

							{is_mobile && <hr className="line_separator_w margin_top_50 margin_bottom_50" />}
						</div>
					} */}

			

					{
						homepage.blog && homepage.blog.length > 0 && <div className="muvTravelBlog" style={{ marginLeft: padding_l + "%", marginRight: padding_r + "%" }}>
							<BlogList />
						</div>
					}
					<div style={{
						paddingLeft: padding_l + "%",
						paddingRight: padding_r + "%",
						background: "#efefef",
						marginTop: is_mobile ? "0px" : "0px",
					}}>
						{/* <Discover /> */}
					</div>
					<FooterWhite />
				</div >
				<ScrollToTop />
			</React.Fragment>
		)
	}
}

HomePageContainer_.propTypes = {
	bodyClientWidth: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => {
	return {
		bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
		bodyClientHeight: state.Setting.htmlBody.bodyClientHeight,

		lang: state.Setting.lang,
		homepage: state.Home.homepage,
		homepageBucketList: state.Home.homepage_bucket_list,
		homepageMuverStyle: state.Home.homepage_muver_style,
		homepageMuverPress: state.Home.homepage_muver_press,

		uuid: state.Member.authModal.uuid,

		is_mobile: state.Setting.is_mobile,


	}

}

const mapDispatchToProps = {

	setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,
	settAppMenuBackGroundColor: Actions.settAppMenuBackGroundColor,
	setBookingFormStatus: Actions.setBookingFormStatus,
}
const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(HomePageContainer_)

export default HomePageContainer