export const generateGoogleCalendarLink = (activity) => {
    try {
        if (!activity || !activity.activityStartTime || !activity.activityDuration) {
            throw new Error("Invalid activity data. Missing required fields.");
        }

        const startDate = new Date(); // Replace with actual date logic
        const startTimeParts = activity.activityStartTime.split(':');
        if (startTimeParts.length !== 2) throw new Error("Invalid start time format.");

        startDate.setHours(parseInt(startTimeParts[0], 10));
        startDate.setMinutes(parseInt(startTimeParts[1], 10));

        const endDate = new Date(startDate);
        const durationParts = activity.activityDuration.split(':');
        if (durationParts.length !== 2) throw new Error("Invalid duration format.");

        endDate.setHours(startDate.getHours() + parseInt(durationParts[0], 10));
        endDate.setMinutes(startDate.getMinutes() + parseInt(durationParts[1], 10));

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            throw new Error("Failed to parse dates.");
        }

        const formattedStart = startDate.toISOString().replace(/-|:|\.\d+/g, "");
        const formattedEnd = endDate.toISOString().replace(/-|:|\.\d+/g, "");

        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(activity.activityName || "Event")}&details=${encodeURIComponent(activity.activityDescription || "")}&location=${encodeURIComponent(activity.activityAddress || "")}&dates=${formattedStart}/${formattedEnd}`;
    
    } catch (error) {
        console.error("Error generating Google Calendar link:", error.message);
        return null; // Return null if an error occurs
    }
};


export const generateICSFile = (activity) => {
    try {
        if (!activity || !activity.activityStartTime || !activity.activityDuration) {
            throw new Error("Invalid activity data. Missing required fields.");
        }

        const startDate = new Date();
        const startTimeParts = activity.activityStartTime.split(':');
        if (startTimeParts.length !== 2) throw new Error("Invalid start time format.");

        startDate.setHours(parseInt(startTimeParts[0], 10));
        startDate.setMinutes(parseInt(startTimeParts[1], 10));

        const endDate = new Date(startDate);
        const durationParts = activity.activityDuration.split(':');
        if (durationParts.length !== 2) throw new Error("Invalid duration format.");

        endDate.setHours(startDate.getHours() + parseInt(durationParts[0], 10));
        endDate.setMinutes(startDate.getMinutes() + parseInt(durationParts[1], 10));

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            throw new Error("Failed to parse dates.");
        }

        const formatICSDate = (date) => date.toISOString().replace(/-|:|\.\d+/g, "");

        const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${formatICSDate(startDate)}
DTEND:${formatICSDate(endDate)}
SUMMARY:${activity.activityName || "Event"}
DESCRIPTION:${activity.activityDescription || ""}
LOCATION:${activity.activityAddress || ""}
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsData], { type: "text/calendar" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = `${activity.activityName || "event"}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.error("Error generating ICS file:", error.message);
    }
};
