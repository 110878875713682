import { Grid } from "@material-ui/core"
import moment from "moment";
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import airplane from '../../../../assets/images/planeGrey.png'
import { DeleteIcon, RightArrowIcon } from "../../Common/SVGBank";
import { multiCityData, roundTripTestData, returnTestData, departureTestData, flightTestContract } from '../services/flightTestData';
import { deleteFlightCart } from '../services/Flights.slice';
import { Modal } from 'react-bootstrap'


export const formatPrice = (price) => {
    const format = price ? price.toString().split(".") : null;
    const wholePrice = format ? format[0] : null;
    const cents = format ? format[1] : null;
    return {
        price: wholePrice,
        cents: cents ? String(cents)?.substring(0, 2) : ""
    }
}

export const FlightTotalComponent = ({ bookFlight }) => {
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const selectedReturnFlight = useSelector(state => state.Flights.selectedReturnFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    const flightAdults = useSelector(state => state.Flights.flightData.numberAdult);
    const flightChildren = useSelector(state => state.Flights.flightData.numberChild)
    const savedFlight = useSelector(state => state.Flights);
    const passengerNum = savedFlight?.selectedFlight?.number_of_passengers || Number(flightAdults) + Number(flightChildren) || 1;
    const passengers = (savedFlight?.selectedFlight?.number_of_passengers || Number(flightAdults) + Number(flightChildren) || 1).toString();
    const flightContract = useSelector(state => state.Flights.flightContract);
    const taxes_and_fees = flightContract?.itinerary_data?.popup_data?.taxes_and_fees?.paragraph_data;
    const baggageInfo = flightContract?.itinerary_data?.popup_data?.baggage_fees?.paragraph_data;
    const priceDetails = flightContract?.itinerary_data?.price_details;


    const flightTotal = priceDetails?.display_total_fare.toFixed(2);
    const currencyName = priceDetails?.display_currency;
    const currencySymbol = priceDetails?.display_symbol;

    const formatTotal = flightTotal ? flightTotal.toString().split(".") : null;
    const wholePrice = formatTotal ? formatTotal[0] : null;
    const cents = formatTotal ? formatTotal[1] : null;

    const flightTotalPerTicket = priceDetails?.display_total_fare_per_ticket.toFixed(2);


    const basePrice = priceDetails?.display_base_fare?.toFixed(2);
    const basePerPerson = priceDetails?.display_base_fare?.toFixed(2);


    const totalBase = (basePrice * passengerNum).toFixed(2).toString();

    const selectedFlightPriceDetails = priceDetails;

    const dispatch = useDispatch();

    const clearFlight = () => {
        dispatch(deleteFlightCart());
    }




    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const showTaxes = () => {
        setModalInfo({
            title: "Taxes and Fees",
            value: taxes_and_fees
        })
        handleShow()
    }

    const showBaggage = () => {
        setModalInfo({
            title: "Baggage Fees",
            value: baggageInfo
        })
        handleShow()
    }
    const [modalInfo, setModalInfo] = React.useState({
        title: "",
        value: null
    });



    const isRecapPage = window.location && window.location.pathname.includes('recap');

    var windowWidth = window.screen.width < window.outerWidth ?
        window.screen.width : window.outerWidth;
    var mobile = windowWidth < 500;


    return (
        <div>
            <Modal show={show} onHide={handleClose} style={{
                maxWidth: '80% !Important', top: "20%"
            }}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalInfo.title}</Modal.Title>
                </Modal.Header>

                {modalInfo && modalInfo?.value && <Modal.Body
                    style={{
                        height: '500px',
                        overflowY: 'auto'
                    }}>
                    {
                        Object.keys(modalInfo?.value).map((key) => {
                            const text = modalInfo?.value[key];

                            return (
                                <div key={key} >
                                    <div style={{ font: "16px/16px Futura Lt BT", lineHeight: 1.3, paddingTop: 10 }} dangerouslySetInnerHTML={{ __html: text }}></div>
                                </div>
                            )
                        })}
                </Modal.Body>}


            </Modal>

            <Grid container style={{ border: '1px solid #DFDFDF', marginBottom: 30, paddingBottom: 30, paddingTop: 10 }} >

                {!selectedDeparture && !selectedReturnFlight &&
                    <div style={{ textAlign: 'left', padding: '10px 0px 20px 20px', marginBottom: -30 }}>  Cart is Empty</div>}

                {priceDetails && <div>
                    {/* {(selectedDeparture || selectedReturnFlight) && <div style={{ textAlign: 'right', width: '100%', padding: '10px 20px 0px 0px', cursor: 'pointer' }} onClick={()=> clearFlight()}>   <DeleteIcon /> </div>} */}
                    {selectedDeparture && selectedDeparture.slice_data.map((slice, index) => {
                        const showBasePrice = index === selectedDeparture.slice_data.length - 1 && !selectedReturnFlight;
                        return (
                            <FlightTotalItem key={index + 'depart'} slice={slice} showBasePrice={showBasePrice} departure={slice.departure} arrival={slice.arrival} selectedDeparture={selectedDeparture} selectedReturn={selectedReturnFlight} flightType={'outgoing'}
                                basePerPerson={basePerPerson} />
                        )
                    })}

                    {selectedReturnFlight && selectedReturnFlight.slice_data.map((slice, index) => {
                        const showBasePrice = index === selectedDeparture.slice_data.length - 1;
                        return (
                            <FlightTotalItem key={index + 'return'} slice={slice} showBasePrice={showBasePrice} departure={slice.departure} arrival={slice.arrival} selectedDeparture={selectedDeparture} selectedReturn={selectedReturnFlight} flightType={'return'}
                                basePerPerson={basePerPerson} />
                        )
                    })}

                    {(selectedDeparture || selectedReturnFlight) && <div style={{ width: '100%', paddingTop: 22 }}>
                        <div style={{ clear: 'both', paddingLeft: 30, paddingRight: 30, }}>
                            <p style={{ font: "14px/14px Futura Md BT", float: 'left' }}>  Total Base Price </p>
                            <p style={{ font: "14px/12px Futura Md BT", float: 'right' }}>
                                {currencyName}{currencySymbol} {formatPrice(totalBase).price}{formatPrice(totalBase).cents && <sup style={{ fontSize: '50%', top: '-0.7em' }}>.{formatPrice(totalBase).cents}</sup>}</p>
                        </div>


                        <div style={{ clear: 'both', paddingLeft: 30, paddingRight: 30, }}>
                            <p style={{ font: "14px/14px Futura Md BT", float: 'left', color: '#4183c4', cursor: 'pointer' }} onClick={showTaxes}>  Taxes and fees</p>
                            <p style={{ font: "12px/12px Futura Md BT", float: 'right' }}>
                                {currencyName}{currencySymbol} {formatPrice(selectedFlightPriceDetails?.display_taxes_and_fees)?.price}.<sup style={{ fontSize: '50%', top: '-0.7em' }}>{formatPrice(selectedFlightPriceDetails?.display_taxes_and_fees).cents}</sup></p>
                        </div>

                        <div style={{ clear: 'both', paddingLeft: 30, paddingRight: 30, }}>
                            <p style={{ font: "14px/14px Futura Md BT", float: 'left' }}>  Nb of passengers</p>
                            <p style={{ font: "12px/12px Futura Md BT", float: 'right' }}> {passengers}</p>
                        </div>


                        <div style={{ clear: 'both', background: '#F5F7F6', paddingLeft: 30, paddingRight: 30, paddingTop: 15, }}>
                            <div style={{}}>
                                <p style={{ font: "14px/14px Futura Hv BT", float: 'left' }}>  TOTAL </p>
                                <p style={{ font: "16px/12px Futura Hv BT", float: 'right', }}>
                                    {currencyName}{currencySymbol} {wholePrice}.<sup style={{ fontSize: '50%', top: '-0.7em' }}>{cents}</sup></p>
                            </div>


                            <div style={{ clear: "both", paddingBottom: 15 }}>
                                <p style={{ font: "12px/12px Futura Lt BT", textAlign: 'right', color: '#7F7F7F', lineHeight: 1 }}>
                                    {currencySymbol}{formatPrice(flightTotalPerTicket).price}.<sup style={{ fontSize: '50%', top: '-0.7em' }}>{formatPrice(flightTotalPerTicket).cents}</sup> per person </p>

                            </div>
                        </div>

                        <div style={{ clear: 'both', paddingLeft: 30, paddingRight: 30, paddingTop: 20 }}>
                            <p style={{ font: "14px/12px Futura Lt BT", color: '#7F7F7F', lineHeight: 1.2 }}>
                                Total price includes taxes and fee. Prices are not guaranteed until purchase is complete.

                                <span style={{ color: '#4183c4', cursor: 'pointer' }} onClick={showBaggage}> Baggage fees</span> are not included in your trip cost.
                            </p>

                        </div>


                    </div>}

                </div>}
                {(selectedDeparture || selectedReturnFlight) && !priceDetails &&
                    <div style={{ textAlign: 'left', padding: '10px 0px 20px 20px', marginBottom: -30 }}>  Loading price</div>}

            </Grid>


            {isRecapPage && !mobile && (selectedDeparture || selectedReturnFlight) && <div style={{ marginTop: -10, textAlign: 'right' }}>
                <button className='recapButtons' style={{ background: '#19BC9B', color: 'white', font: '14px/14px Futura Hv BT' }} onClick={bookFlight}>
                    <span style={{ paddingRight: 60, font: '14px/14px Futura Hv BT' }}>  Book Now     </span>
                    <RightArrowIcon height={14} width={14} />
                </button>

                <p style={{ width: 200, float: 'right', clear: 'both', font: "14px/12px Futura Lt BT", paddingTop: 15, color: '#7F7F7F', lineHeight: 1.2 }}>
                    By Selecting "Book Now" I agree to this purchase of {currencyName}{currencySymbol} {flightTotal}. I have read and accept the <a target="_blank" href="/terms-and-conditions">terms and conditions</a>, <a target="_blank" href="/privacy-policy">privacy policy</a> and important terms
                </p>
            </div>}


        </div>

    )
}

export const FlightTotalItem = ({ departure, arrival, selectedDeparture, selectedReturn, flightType, slice, showBasePrice, basePerPerson }) => {
    const departureDate = moment(departure.datetime.date).format('MMM Do');
    const arrivalDate = moment(arrival.datetime.date).format('MMM Do');
    const priceDetails = selectedReturn ? selectedReturn.price_details : selectedDeparture.price_details;
    // const flightTotalPerTicket = priceDetails.display_base_fare.toFixed(2);

    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    let flightTotal = 0
    if (selectedFlightMode === 'round_trip') {
        flightTotal = flightType === 'return' ? Number(selectedReturn.price_details.display_total_fare - selectedDeparture.price_details.display_total_fare).toFixed(2) : selectedDeparture.price_details.display_total_fare.toFixed(2);
    }
    else {
        flightTotal = priceDetails?.display_total_fare?.toFixed(2);
    }

    const currencyName = priceDetails.display_currency;
    const currencySymbol = priceDetails.display_symbol;

    const formatPrice = flightTotal ? flightTotal.toString().split(".") : null;
    const wholePrice = formatPrice ? formatPrice[0] : null;
    const cents = formatPrice ? formatPrice[1] : null;


    const showPerPerson = flightType === 'outgoing' && selectedFlightMode === 'round_trip' ? false : true;
    return (
        <Grid container>
            <Grid container spacing={0} style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 30, paddingBottom: 10 }} className="flightTotalTimeline" >
                <div className='TotalLine'></div>
                <Grid item md={4} xs={4} style={{ paddingBottom: 10, textAlign: 'left', marginRight: -10 }} >
                    <TotalFlightComponent code={departure.airport.code} city={departure.airport.city} airport={departure.airport.name} day={departureDate} time={departure.datetime.time_24h}
                        flightInfo={slice} />
                </Grid>
                <Grid item md={4} xs={4} style={{}} >
                    <div style={{ width: 55, background: '#fff', marginTop: 4, marginLeft: 10 }}>
                        <img src={airplane} height={15} alt="airplane" />
                    </div>

                </Grid>

                <Grid item md xs={4} style={{ paddingBottom: 10, textAlign: 'left', margin: '0 auto', background: 'white', paddingLeft: 10 }} >
                    <TotalFlightComponent code={arrival.airport.code} city={arrival.airport.city} airport={arrival.airport.name} day={arrivalDate} time={arrival.datetime.time_24h} />
                </Grid>
            </Grid>
            <TotalPriceComponent flightMode={selectedFlightMode} currencyName={currencyName} showPerPerson={showPerPerson}
                currencySymbol={currencySymbol} wholePrice={wholePrice} cents={cents} showBasePrice={showBasePrice}
                basePerPerson={basePerPerson} />
        </Grid>
    )
}

export const TotalPriceComponent = ({ flightMode, currencyName, currencySymbol, wholePrice, cents, pricePerson, showPerPerson, showBasePrice, basePerPerson }) => {
    const formatPrice = basePerPerson ? basePerPerson.toString().split(".") : null;
    const personPrice = formatPrice ? formatPrice[0] : null;
    const personPriceCents = formatPrice ? formatPrice[1] : null;



    return (

        <div style={{ background: '#F5F7F6', width: '100%', textAlign: 'right', paddingLeft: 30, minHeight: 25, paddingRight: 30, paddingTop: 10, paddingBottom: 10 }}>
            {showBasePrice && <div style={{ float: 'left', }}
            >  <span style={{
                color: '#707070', lineHeight: 1.6, font: "14px/14px Futura Md BT"
            }}>Base Price per person </span> </div>}
            {showBasePrice ? <div style={{ font: "14px/14px Futura Md BT", float: 'right' }}>
                {flightMode === 'multi_destination' ? 'INC' :
                    <div>
                        {showPerPerson && <div style={{ color: '#707070', lineHeight: 1.6 }}>  {currencyName} {currencySymbol}{personPrice}.<sup style={{ fontSize: '50%', top: '-0.7em' }}>{personPriceCents}</sup> </div>}

                    </div>
                }
            </div> : <div style={{ height: 25 }}></div>}
        </div>
    )
}

export const TotalFlightComponent = ({ code, city, airport, day, time, flightInfo }) => {
    const stops = flightInfo?.info?.connection_count;
    const stopLabel = stops && stops > 0 ? stops === 1 ? "1 Stop" : `${stops} Stops` : stops === 0 ? "Non stop" : null;

    return (
        <div>
            <div style={{ font: "18px/18px Futura Md BT", background: '#fff', width: 55, lineHeight: 1.6 }}> {code} </div>
            {/* <div style={{ font: "12px/12px Futura LT BT", lineHeight: 1.3, color: '#7F7F7F' }}> {city} </div> */}
            <div style={{ font: "12px/12px Futura LT BT", lineHeight: 1.3, color: '#7F7F7F' }}> {airport} </div>
            {stopLabel && <div style={{ font: "12px/12px Futura LT BT", lineHeight: 1.3, paddingTop: 3, color: "#E63155", }}> {stopLabel} </div>}
            <div style={{ font: "12px/12px Futura Hv BT", lineHeight: 1.6, marginTop: 15 }}> {day} @ {time}</div>
        </div>
    )
}