import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import './App.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ReactGA from 'react-ga';
import { useHistory, useLocation } from 'react-router-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { hotjar } from 'react-hotjar';
import BookingPage from './Components/WebContent/BookingPage/BookingPage.jsx'
import DestinationExplorePage from './Components/WebContent/DestinationPage/DestinationExplorePage.jsx'
import DestinationPage from './Components/WebContent/DestinationPage/DestinationPage.jsx'
import PrintPage from './Components/WebContent/PrintPage/PrintPage.jsx'
import HotelListingsPage from './Components/WebContent/DestinationPage/HotelListingsPage.jsx'
import EstablishmentPage from './Components/WebContent/EstablishmentPage/EstablishmentPage.jsx'
import HotelPage from './Components/WebContent/HotelPage/EstablishmentPage.jsx'
import FeaturePage from './Components/WebContent/FeaturePage/FeaturePage.jsx'
import { GOOGLE_API_KEY } from "./Components/WebContent/Common/RegExValidate"
import HomePage from './Components/WebContent/HomePage/HomePage.jsx'
import LandingPage from './Components/WebContent/LandingPage/LandingPage.jsx'
import PageNotFound from './Components/WebContent/ErrorPage/PageNotFound.jsx'
import React from 'react'
import ScrollToTop from './Components/WebContent/Common/ScrollToTop.jsx'
import SnackBars from "./Components/WebContent/Common/SnackBars"
import SocialPage from './Components/WebContent/SocialPage/SocialPage.jsx'
import StarredPage from './Components/WebContent/StarredPage/StarredPage.jsx'
import TripPage from './Components/WebContent/TripPage/TripPage.jsx'
import BucketListPage from './Components/WebContent/BucketListPage/BucketListPage';
import RegisterPage from './Components/WebContent/LandingPage/RegisterPage.jsx'
import PrivateRoute from './PrivateRoute';
import MuverPage from './Components/WebContent/MuverPage/MuverPage';
import { environment } from './Environments/environment';
import FlightsPage from './Components/WebContent/FlightsPage/FlightsPage';
import { generateSessionId } from './utility/utility';
import FlightsRecapPage from './Components/WebContent/FlightsPage/RecapPage/FlightRecapPage';
import FlightPaymentPage from './Components/WebContent/FlightsPage/PaymentPage/PaymentPage';
import HotelPaymentPage from './Components/WebContent/BookingPage/PaymentPage/PaymentPage';
import TermsConditionPage from './Components/WebContent/TermsCondition/TermsConditionPage';
import FlightManagementPage from './Components/WebContent/FlightsPage/FlightManagement/FlightManagementPage';
import HotelManagementPage from './Components/WebContent/BookingPage/HotelManagement/HotelManagementPage';
import TimelinePage from './Components/WebContent/TimelinePage/TimelinePage';
import RouteChangeTracker from './RouteTracker';
import SuggestedDestinationsPage from './Components/WebContent/SuggestedDestinationsPage/SuggestedDestinationsPage';
import InfluencerPage from './Components/WebContent/InfluencerPage/InfluencerPage';
import ContactUsPage from './Components/WebContent/ContactUs/ContactUsPage';
import MuverTripPage from './Components/WebContent/MuverTripPage/MuverTripPage';
import PackagePaymentPage from './Components/WebContent/Package/PaymentPage/PaymentPage';
import PackageRecapPage from './Components/WebContent/Package/RecapPage/PackageRecapPage';
import BaseComponents from './BaseComponents';
import AIHomePage from './Components/WebContent/AIHomePage/AIHomePage';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import StayHomePage from './Components/WebContent/StayHomePage/StayHomePage';
import ResetPasswordPage from './Components/WebContent/ResetPasswordPage/ResetPasswordPage';
import AiChatBox from './Components/WebContent/Common/AiChatBox/AiChatBox';
import SuccessPaymentFlightPage from './Components/WebContent/FlightsPage/PaymentPage/SucesssPaymentFlightPage';
import SuccessHotelPaymentPage from './Components/WebContent/BookingPage/PaymentPage/SuccessHotelPaymentPage';
import MuvHotelListingsPage from './Components/WebContent/DestinationPage/MuvHotelListingsPage';
import MuvHotelPage from './Components/WebContent/MuvHotelPage/EstablishmentPage';
import MuvBookingPage from './Components/WebContent/MuvBookingPage/MuvBookingPage';
import MuvPaymentPage from './Components/WebContent/MuvPaymentPage/MuvPaymentPage';
import MuvSuccessPaymentPage from './Components/WebContent/MuvPaymentPage/MuvSuccessPaymentPage';
import MuvManagementPage from './Components/WebContent/MuvManagementPage/MuvManagementPage';


// const pageViewsGetter = () => {
//   console.log(window.location.pathname)
//   console.log(window.localStorage.getItem('location'))
//   window.localStorage.getItem('location') !== "/" && window.location.assign(window.localStorage.getItem('location'))
// }

// const PageViewsSetter = () => {
//   window.localStorage.clear()
//   window.localStorage.setItem('location', window.location.pathname)
//   window.history.pushState("", "", "/")
// }


/* root container component */
const Switcher = () => {


  const routes = [
    {
      path: "/flights",
      component: HomePage,
    },
    {
      path: "/landing",
      component: LandingPage,
    },
    {
      path: "/inspire",
      component: InfluencerPage,
    },
    {
      path: "/timeline",
      component: TimelinePage,
    },
    {
      path: "/destination-explore/:desc/:destinationID/:tripID",
      component: DestinationExplorePage,
    },
    {
      path: "/old-destination-:desc-:destinationID",
      component: DestinationPage,
    },
    {
      path: "/destination/:desc/:featureID/:destinationID",
      component: FeaturePage,
    },
    {
      path: "/print-:desc-:tripID",
      component: PrintPage,
    },
    {
      path: "/hotel-:desc-:establishmentPricelineID",
      component: HotelPage,
    },
    {
      path: "/hotel-landing",
      component: HotelListingsPage,
    },
    {
      path: "/muv-hotel-:desc-:establishmentPricelineID",
      component: MuvHotelPage,
    },
    {
      path: "/hotel",
      component: HotelPage,
    },
    {
      path: "/hotel-search",
      component: HotelListingsPage,
    },
    { 
      path: "/muv-hotel-search",
      component: MuvHotelListingsPage,
    },
    {
      path: "/flight/search",
      component: FlightsPage,
    },
    {
      path: "/flight/recap",
      component: FlightsRecapPage,
    },
    {
      path: "/flight/payment",
      component: FlightPaymentPage,
    },
    {
      path: "/muv/payment",
      component: MuvPaymentPage,
    },
      {
      path: "/payment/success",
      component: MuvSuccessPaymentPage,
    },
    {
      path: "/payment/details",
      component: MuvManagementPage,
    },
    {
      path: "/flight/confirmation",
      component: SuccessPaymentFlightPage,
    },
    {
      path: "/package",
      component: PackageRecapPage,
    },
    {
      path: "/package/payment",
      component: PackagePaymentPage,
    },
    {
      path: "/flight/booking_details",
      component: FlightManagementPage,
    },
    {
      path: "/search-hotels/:city_id/:check_in/:check_out/:rooms/:guests/:where",
      component: HotelListingsPage,
    },
    {
      path: "/establishment/:desc/:establishmentID",
      component: EstablishmentPage,
    },
    {
      path: "/bucketlist/:desc/:bucketItemID",
      component: BucketListPage,
    },
    {
      path: "/feature/:desc/:featureID",
      component: FeaturePage,
    },
    {
      path: "/trending-:desc/:featureID&:type=:typeID",
      component: FeaturePage,
    },

    {
      path: "/error",
      component: PageNotFound,
    },
    {
      path: "/ai",
      component: AIHomePage,
    },
    {
      path: "/stay",
      component: StayHomePage,
    },
    {
      path: "/suggested-destinations",
      component: SuggestedDestinationsPage,
    },
    {
      path: "/starred",
      component: StarredPage,
    },
    {
      path: "/terms-and-conditions",
      component: TermsConditionPage,
    },
    {
      path: "/privacy-policy",
      component: TermsConditionPage,
    },
    {
      path: "/contact-us",
      component: ContactUsPage,
    },
    {
      path: "/booking",
      component: BookingPage,
    },
    {
      path: "/muv-booking",
      component: MuvBookingPage,
    },
    {
      path: "/hotel/payment",
      component: HotelPaymentPage,
    },
    {
      path: "/hotel/confirmation",
      component: SuccessHotelPaymentPage,
    },
    {
      path: "/hotel/booking_details",
      component: HotelManagementPage,
    },

    {
      path: "/booking-:tripID",
      component: BookingPage,
    },
  ]

  const BlackPageRouteComponent = (props) => {
    const url = environment.prodUrl + props.location.pathname;
    window.location.assign(url)
    return null;
  }

  const privateRoutes = [
    {
      path: "/destination/:desc/:featureID/:destinationID/:tripID",
      component: FeaturePage, //destination trip page
    },
    {
      path: "/muver/trippage/:tripID",
      component: MuverTripPage,
    },
    {
      path: "/muver/:area",
      component: MuverPage,
    },
    {
      path: "/muver/:area/:tripID",
      component: MuverPage,
    },
    {
      path: "/muver/:area/:container/:tripID",
      component: MuverPage,
    },
  ]

  return (
    <React.Fragment>
      <Switch>
        {/* shared routes(muv.travel & main site) */}
        {routes.map((route, i) => {
          let component = environment.isBlackPage ? BlackPageRouteComponent : route.component;
          return <Route exact key={i} path={route.path} component={component} />
        }

        )}

        {privateRoutes.map((route, i) => {
          let component = environment.isBlackPage ? BlackPageRouteComponent : route.component;
          return environment.isBlackPage ? <Route exact key={i} path={route.path} component={component} />
            : <PrivateRoute exact key={i} path={route.path} component={component} />
        }
        )}


        {/* establishment redirect  */}
        <Redirect from="/:lang/muv_establishments/establishment/:id-:desc" to="/establishment/:desc/:id" />

        {/* place redirect  */}
        <Redirect from="/:lang/place/select/:id-:desc" to="/destination/:desc/1/:id" />



        {/* wildcard routes */}
        <Route exact path="/register" component={RegisterPage} />
        <Route exact path="/register/ai" component={RegisterPage} />
        <Route exact path="/login" component={RegisterPage} />
        <Route path="/account/reset-password" component={ResetPasswordPage} />
        <Route exact path="/login/ai" component={RegisterPage} />
        <Route exact path="/trip/:desc/:tripID" component={TripPage} />
        <Route exact path="/tripshare-:shareID" component={TripPage} />
        <Route exact path="/" component={environment.isBlackPage ? LandingPage : HomePage} />
        <Route exact path="/:handle" component={SocialPage} />
        <Route component={PageNotFound} />

      </Switch>
    </React.Fragment>
  )
}

const GoogleURL = () => {
  const [load, setLoad] = React.useState(false)
  const loadScript = (src, position) => {
    if (!position) {
      return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;
    position.appendChild(script);
  }
  if (typeof window !== 'undefined'
    && !load
  ) {
    setLoad(true)
    loadScript(
      'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API_KEY + '&libraries=geometry,drawing,places',
      document.querySelector('head')
    );
  }
  return <React.Fragment />
}



const App = () => {
  const { uuid } = useSelector(state => state.Member.authModal);
  generateSessionId()

  const TRACKING_ID = `${environment.googleTrackingId}`;
  ReactGA.initialize(TRACKING_ID);



  React.useEffect(() => {
    TagManager.initialize({ gtmId: environment.gtmId });
    hotjar.initialize(environment.hjid, environment.hjsv);
  }, []);

  React.useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.identify(uuid, { userProperty: 'value' });
    }
  }, [uuid]);///identify user
  const pathname = window.location.pathname;






  return (
    <div style={{ paddingBottom: 0 }}>
      <BrowserRouter>
        <Switcher />
        <BaseComponents />
        <ScrollToTop />
        <SnackBars />
        <GoogleURL />
        <RouteChangeTracker />


      </BrowserRouter>
    </div>
  )
}

export default App

/* app component */