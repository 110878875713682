import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Actions from '../../../../redux/actions'
import React, { useRef } from 'react'
import { faHandSparkles, faWandMagicSparkles, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { faSparkles } from "@fortawesome/free-solid-svg-icons";
import { Stars } from '@material-ui/icons'
import SparklesLogo from "../../../../../src/assets/images/sparkle.png"

const MuvGptLogo = () => {

    React.useEffect(() => {
    }, [])




    return (

        <div>
            <div style={{
                color: 'var(--mainGreen)',
                font: "18px/24px Futura Hv BT",
            }}>
                    <img src={SparklesLogo} height={25} width={25} 
                    alt="sparkles"/>
      
                {/* <FontAwesomeIcon size="md" icon={faSparkles} style={{ marginRight: "5px" }} /> */}

                müvGPT
                <span style={{
                    position: 'absolute',
                    bottom: '-5px', // Position the label just below the text
                    right: '0', // Align it to the right of the div
                    color: 'var(--mainRed)', // You can change this color as needed
                    font: "10px/12px Futura Hv BT",
                }}>
                    BETA
                </span>
            </div>
        </div>
    )

}

export default MuvGptLogo
