import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { FlightSearchComponent } from '../FlightsSearch'
import { ScrollTopLazyLoading } from '../../Common/ScrollTopLazyLoading';
import { Backdrop, CircularProgress, Fab, Grid, makeStyles } from '@material-ui/core';
import { AngleUpIcon } from '../../Common/SVGBank';
import { useHistory } from "react-router-dom";
import { FlightRecapList } from './FlightRecapList';
import { FlightTotalComponent } from './FlightTotalComponent';
import { CongratulationsSavedFlight, FlightFooter } from './FlightSharedComponents';
import { setOpenAuthModal } from '../../../../redux/actions';
import { FlightPaymentForm } from '../PaymentPage/PaymentForm';
import { getFlightContractThunk, getNearByDestinationCode, saveFlightTimeline } from '../services/Flights.thunks';
import { FlightExpired } from '../flightExpired';
import { setExpiredFlight } from '../services/Flights.slice';
import { URL_REPLACE } from "../../Common/RegExValidate";
import { ALERT_BARS_HANDLE, SET_TIMELINE_WIZARD_STATUS } from '../../../../redux/interfaces/types';
import { HotelFlightSaveModal } from './FlightSaveModal';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const FlightsRecapContainer_ = ({ lang, uuid, bodyClientWidth, pageYOffset, is_mobile, timelineWizardStatus, activeTrip }) => {
    const params = useParams()
    const classes = useStyles();
    const history = useHistory();
    const nearByDestination = useSelector(state => state.Flights.nearByDestination);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);
    const selectedFlightMode = useSelector(state => state.Flights.selectedFlightMode);
    const numberAdult = useSelector(state => state.Flights.flightData.numberAdult);
    const numberChild = useSelector(state => state.Flights.flightData.numberChild);
    let arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight?.slice_data?.[selectedFlight.slice_data.length - 1]?.arrival?.airport?.code : '';
    if (selectedFlightMode === 'round_trip') {
        arrivalCode = selectedFlight && selectedFlight.slice_data ? selectedFlight?.slice_data[selectedFlight?.slice_data.length - 1]?.departure?.airport?.code : '';
    }
    const activeTripId = activeTrip?.tripId;
    let width;
    let marginLeft;
    React.useEffect(() => {

        if (arrivalCode) {
            dispatch(getNearByDestinationCode(arrivalCode));
        }

        //    dispatch(getFlightContractThunk())
    }, [])


    let positionScrollToTopLazyLoading = ""
    if (!isNaN(is_mobile)) {
        width = is_mobile ? '100%' : '67%';
        marginLeft = is_mobile ? '0%' : timelineWizardStatus ? '31%' : '15.5%';
        positionScrollToTopLazyLoading = is_mobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }
    const dispatch = useDispatch()
    const loading = useSelector(state => state.Flights.loading);
    const [saveToTrip, setSaveToTrip] = React.useState(false);
    const [bookingFlight, setBookFlight] = React.useState(false);
    const flightListData = useSelector(state => state.Flights.flightListData);

    const [showSaveModal, setSaveModal] = React.useState(false);

    const authModal = useSelector(state => state.Member.authModal);
    const Flights = useSelector(state => state.Flights);


    const isLoggedIn = () => {
        return authModal.uuid && authModal?.uuid?.length > 0;
    }

    const goToDestination = () => {
        if (nearByDestination) {
            const url = "/destination/" + nearByDestination.name.replace(URL_REPLACE, "-") + "/" + 1 + "/" + nearByDestination.destinationId;
            history.push(url.toLowerCase())
            ///window.open(url.toLowerCase());
        }
    }


    const toggleSaveModal = () => {
        setSaveModal(!showSaveModal)

        if (showSaveModal) {
            setBookFlight(false)
        }
    }


    const saveBooking = () => {
        if (isLoggedIn()) {
            if (activeTripId && timelineWizardStatus) { ///timeline open
                addToTimeline(activeTripId)
            }
            else if (activeTripId && !timelineWizardStatus) {  //timeline closed
                toggleSaveModal()
            }
            else {
                addToTimeline(null)
            }

        } else {
            dispatch(setOpenAuthModal(true));
        }
    }


    React.useEffect(() => {
        if (!isLoggedIn()) {
            dispatch({
                type: ALERT_BARS_HANDLE,
                message_type: "warning",
                message: "Please login to save this trip"
            })
        }
    }, [authModal.uuid])


    const addToTimeline = async (tripId) => {
        const { selectedDepartureFlight, selectedReturnFlight, selectedFlightMode } = Flights;
        const selected = selectedFlightMode === 'round_trip' && selectedReturnFlight ? selectedReturnFlight : selectedDepartureFlight ? selectedDepartureFlight : null;

        const saveData = {
            postData: {
                tripId: tripId,
                booking_trip_number: "",
                booking_status: "",
                booking_status_code: "",
                airports: Flights?.savedFlight || [],
                displaySymbol: selected?.price_details?.display_symbol,
                displayCurrency: selected?.price_details?.display_currency,
                displayTotalFare: selected?.price_details?.display_total_fare,
                adults: numberAdult,
                children: numberChild
            },
            uuid: uuid || null
        }

        let responseContract;

        //only for booking
        if (bookingFlight) {
            responseContract = await dispatch(getFlightContractThunk());

            if (responseContract) {
                if (responseContract?.meta?.requestStatus === "fulfilled") {
                    history.push('/flight/payment')
                    await dispatch(saveFlightTimeline(saveData));
                    // const destinationID = response?.payload?.result?.destinationId;
                    // const destinationName = response?.payload?.result?.destinationName;
                }
                else {
                    dispatch(setExpiredFlight(true));
                }
                setBookFlight(false);
            }
            else {
                dispatch(setExpiredFlight(true));
            }
        }
        else {
            ///only for saving
            const response = await dispatch(saveFlightTimeline(saveData));
            dispatch({
                type: SET_TIMELINE_WIZARD_STATUS,
                status: true
            })


            // const tripDestinations = response?.payload?.result?.tripDestinations;
            // const destName = tripDestinations && tripDestinations?.length > 0? tripDestinations[tripDestinations?.length-1]?.name:"";
            // const destId = tripDestinations && tripDestinations?.length > 0? tripDestinations[tripDestinations?.length-1]?.destId:"";
            // if (destName && destId) {
            //     setTimeout(() => {
            //      const url = "/destination/" + destName + "/" + 1 + "/" + destId;
            //      history.push(url)
            //     }, 1000);
            //  }
            setBookFlight(false);

            setTimeout(() => {
                goToDestination()
            }, 1000);

        }





        // navigateBooking(destinationName, destinationID)
        setBookFlight(false)
        return (responseContract)
    }

    //handle book clicked
    React.useEffect(() => {
        if (bookingFlight) {
            if (isLoggedIn()) {

                if (activeTripId && timelineWizardStatus) { ///timeline open
                    addToTimeline(activeTripId)
                }
                else if (activeTripId && !timelineWizardStatus) { //timeline closed
                    toggleSaveModal()

                }
                else { ///no timeline
                    addToTimeline(null)
                }

            }
            else {
                addToTimeline(null)
                dispatch({
                    type: ALERT_BARS_HANDLE,
                    message_type: "error",
                    message: "You must log in to save this"
                })
            }
        }
    }, [bookingFlight])

    const bookFlight = async () => {
        setBookFlight(true);
    }


    const navigateBooking = (destinationName, destinationID) => {
        if (destinationName && destinationID) {
            const url = "/destination/" + destinationName + "/" + 1 + "/" + destinationID;
            history.push(url)
        }
    }


    return (
        <div>
            <div style={{
                position: "relative",
                width: width,
                marginLeft: marginLeft,
                paddingBottom: 200
            }}>
                <FlightSearchComponent bodyClientWidth={bodyClientWidth} />
                <div id="back_to_top_flights"></div>

                {/* {(flightListData.length > 0) && <div style={{
                    font: "16px/16px Futura Md BT", color: "#E63155", textAlign: "center", padding: '20px 0px', margin: '20px 0px', border: '1px solid #DFDFDF'
                }}> Free cancellation within 24hrs of booking</div>} */}

                <Grid container className="flightRecap">

                    <Grid item md={8} sm={12} xs={12} className="flightRecapBody" >
                        <FlightRecapList />
                    </Grid>

                    {/* spacing */}
                    <Grid item xs={false} sm={false} md={1} style={{ maxWidth: 40 }}>
                    </Grid>


                    <Grid item md sm={12} xs={12} >
                        <FlightTotalComponent bookFlight={bookFlight} />
                    </Grid>


                </Grid>


                {/* footers */}
                <Grid item md={8} sm={12} xs={12} className="flightRecapBody" >
                    <FlightFooter saveBooking={saveBooking} bookFlight={bookFlight}
                        showSave={true} />

                </Grid>


                <FlightExpired />
                <HotelFlightSaveModal navigateBooking={navigateBooking} showModal={showSaveModal} bookingFlight={bookingFlight} toggleModal={toggleSaveModal} addToTimeline={addToTimeline}
                    type={"flight"} />
                <Backdrop className={classes.backdrop} open={bookingFlight} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>



            <ScrollTopLazyLoading targetId={"#back_to_top_flights"} rightPosition={positionScrollToTopLazyLoading}>
                <Fab size="small" aria-label="scroll back to top" style={{
                    outline: "0px",
                    backgroundColor: '#19BC9B',
                }}>
                    <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                </Fab>
            </ScrollTopLazyLoading>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        pageYOffset: state.Setting.screenVertical.pageYOffset,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        timelineWizardStatus: state.FormBank.TimelineWizard.status,
        uuid: state.Member.authModal.uuid,
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset
}

const FlightsRecapContainer = connect(mapStateToProps, mapDispatchToProps)(FlightsRecapContainer_)
export default FlightsRecapContainer
