  // Set item in localStorage with expiration time
export const setCookie = (name, value, hours = 24) => {
  const expiresAt = new Date().getTime() + hours * 60 * 60 * 1000; // Expiration time
  const item = {
      value,
      expiresAt,
  };
  localStorage.setItem(name, JSON.stringify(item));
};

// Retrieve item from localStorage
export const getCookie = (name) => {
  const itemStr = localStorage.getItem(name);
  if (!itemStr) return null; // Item not found

  const item = JSON.parse(itemStr);
  if (new Date().getTime() > item.expiresAt) {
      localStorage.removeItem(name); // Remove expired item
      return null;
  }
  return item.value;
};

// Delete item from localStorage
export const deleteCookie = (name) => {
  localStorage.removeItem(name);
};