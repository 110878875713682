
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../redux/actions'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'

import { ALERT_BARS_HANDLE } from '../../../redux/interfaces/types'
import "./MuvBookingContainer.css"
import MuvBookingBasicInfo from './MuvBookingBasicInfo'
import MuvBookingItems from './MuvBookingItems'
import { retreiveCart } from '../BookingPage/PaymentPage/cart.thunk'

const MuvBookingContainer_ = ({ props, is_mobile, establishment, timelineStatus, hotelSearchForm,
    logo_height, app_menu_height, lang, uuid, setHotelBookingState, setOpenAuthModal, booking_info, fetchHotelBooking, booking_bundles
}) => {

    const [menu, setMenu] = React.useState("rooms")
    const [loadingCart, setLoadingCart] = React.useState(false);
    const cartLoading = useSelector(state => state.HotelBooking.cart.loading)
    const cartData = useSelector(state => state.HotelBooking.cart.data)

    const dispatch = useDispatch()
    //console.log(props.match.params.establishmentPricelineID);

    var searchParams = new URLSearchParams(document.location.search.substring(1));


    const cart_uuid = searchParams.get("cart_uuid")

    let padding_l = 15
    let padding_r = 15


    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? "31.5" : "15.5")
        padding_r = is_mobile ? "1" : (timelineStatus ? "5" : "15.5")
    }


    const isLoggedIn = () => {
        return uuid && uuid.length > 0
    }

    const signIn = () => {
        setOpenAuthModal(true)
    }


    React.useEffect(() => {
        const userId = uuid || "TMPUUID";
        if (userId && cart_uuid) {
            dispatch(retreiveCart({
                uuid:userId,
                cart_uuid
            }))
        }
    }, [uuid, cart_uuid])

    const CartComponent = () => {
        return cartData &&


            <div style={{
                width: is_mobile ? "100%" : "346px",
                margin: is_mobile ? "0 auto" : "0",
                display: is_mobile ? "block" : "inline-block"
            }} className="cartItem">
                <div style={{ marginBottom: 15 }} >
                    <MuvBookingItems 
                        showMultiple={false}/>
                </div>
            </div>

    }

    return (
        <React.Fragment>

            {cartLoading && <Loader
                type="Rings"
                color="#19BC9B"
                className="se-pre-con"
                height={150}
                width={150}
                style={{
                    backgroundColor: "#FFFFFF"
                }} />}
            <div style={{ position: "relative" }}>
                <div style={{ flexGrow: 1 }}>


                    {!(uuid !== undefined && uuid !== null && uuid !== "") &&
                        <div style={{
                            paddingLeft: padding_l + "%",
                            paddingRight: padding_r + "%",
                            position: "relative",
                            //zIndex: "4",
                            backgroundColor: "var(--mainWhite)",
                            paddingTop: is_mobile ? 80 : "15px",
                        }}>
                            <div style={{ font: is_mobile ? "12px/24px Futura Md BT" : "14px/24px Futura Md BT", color: "#000000", }}>Already a member?</div>
                            <div style={{ font: is_mobile ? "12px/24px Futura Lt BT" : "14px/24px Futura Lt BT", color: "#707070", display: "inline-block" }}>Sign in or register to save your selections and bookings to your account</div>
                            <div onClick={signIn} style={{ font: is_mobile ? "14px/24px Futura Hv BT" : "14px/24px Futura Hv BT", color: "#19BC9B", display: "inline-block", marginLeft: 30, cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: "5px" }} />Sign in</div>

                        </div>}

                    {cartData &&
                        <div style={{
                            paddingLeft: padding_l + "%",
                            paddingRight: padding_r + "%",
                            marginTop: is_mobile ? 66 : "20px"
                        }}>
                            {is_mobile && <CartComponent />}
                            <div style={{
                                width: is_mobile ? "100%" : "calc(100% - 346px)",
                                display: "inline-block",
                                verticalAlign: "top"
                            }} className="hotelRecapInfo">
                                <MuvBookingBasicInfo booking_bundles={booking_bundles} booking_info={booking_info} />
                            </div>
                            {!is_mobile && <div style={{ display: "inline-block" }} className="hotelRecapCart" > <CartComponent /></div>}

                        </div>}
                </div>

            </div>

        </React.Fragment >
    )
}




const mapStateToProps = state => {
    return {
        timelineStatus: state.FormBank.TimelineWizard.status,
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,

        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,

        establishment: state.FormBank.HotelSearch.establishment,
        booking_info: state.FormBank.BookingPriceline.booking_info,
        booking_bundles: state.FormBank.BookingPriceline.booking_bundles,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        //check_in: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_in]'],
        //check_out: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_out]'],
        //rooms: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[rooms]'],
        //adults: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[adults]'],
    }
}

const mapDispatchToProps = {
    fetchHotelEstablishment: Actions.fetchHotelEstablishment,
    fetchHotelBooking: Actions.fetchHotelBooking,
    //setHotelSearchState: Actions.setHotelSearchState,
    //fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,
    //fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
    //fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
    //fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,

    setHotelEstablishmentId: Actions.setHotelEstablishmentId,
    setHotelBookingState: Actions.setHotelBookingState,
    setOpenAuthModal: Actions.setOpenAuthModal,
}

const MuvBookingContainer = connect(mapStateToProps, mapDispatchToProps)(MuvBookingContainer_)
export default MuvBookingContainer
