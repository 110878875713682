import * as Actions from '../../../redux/actions'

import { Element, Link } from 'react-scroll'

import About from './About'
import Amenities from './Amenities'
import Policies from './Policies'
import Experiences from './Experiences'
import Rooms from './Rooms'
import Neighborhood from './Neighborhood'
import Breadcrumb from './Breadcrumb'
import Contact from './Contact'
import EstablishmentBasicInfo from './EstablishmentBasicInfo'
import ReviewContainer from "./ReviewContainer"
//import EstablishmentReviews from '../Common/Establishment/EstablishmentReviews'
import { Menu } from 'semantic-ui-react'
import React from 'react'
import TopBarSlider from './TopBarSlider'
import { connect, useDispatch, useSelector } from 'react-redux'
import HotelSearchFormEmbedded from "../Common/BookingForm/HotelSearchFormEmbedded"
import { useHistory } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { URL_REPLACE } from '../Common/RegExValidate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { MobileHotelHeader } from './MobileHotelHeader'
import moment from 'moment'
import { OTPRegistrationModal } from './OTPRegistration'
import { decryptID, encryptID, isNumeric } from './hotelServices'
import { FetchCachedPullPrice } from '../BookingPage/PaymentPage/payment.thunk'
import MuvHotelSearchFormEmbedded from '../Common/BookingForm/MuvHotelSearchFormEmbedded'

const MuvEstablishmentContainer_ = ({fetchHPHotelRooms, props, sliderImages, setHotelEstablishmentId, is_mobile, establishment,
    logo_height, preload, app_menu_height, lang, fetchHotelEstablishment, fetchHotelRooms, fetchPhotoGalleryEstablishment, fetchExperiencesEstablishment,
    hotelSearchForm, fetchGoogleAndYelpReviewsEstablishment, setHotelSearchState, timelineStatus,
}) => {
    const [menu, setMenu] = React.useState("rooms")
    const [loaded, setLoaded] = React.useState(false)
    const [showHotelForm, setHotelForm] = React.useState(false)
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const history = useHistory()
    searchParams.forEach((value, key) => {
        //console.log(value, key);
    });

    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";


    const hotelID = searchParams.get("hotelID");
    const checkinDay = searchParams.get("checkinDay");
    const checkinMonth = searchParams.get("checkinMonth");
    const checkinYear = searchParams.get("checkinYear");;
    const checkInDate = checkinDay && checkinMonth && checkinYear ? `${checkinYear}-${checkinMonth}-${checkinDay}` : null;
    const checkoutDay = searchParams.get("checkoutDay");
    const checkoutMonth = searchParams.get("checkoutMonth");
    const checkoutYear = searchParams.get("checkoutYear");
    const checkOutDate = checkoutDay && checkoutMonth && checkoutYear ? `${checkoutYear}-${checkoutMonth}-${checkoutDay}` : null;
    const numGuests = searchParams.get("num-guests");




    const city_id = searchParams.get("city_id")
    const check_in = checkInDate || searchParams.get("check_in")
    const check_out = checkOutDate || searchParams.get("check_out")
    const rooms = searchParams.get("rooms") || "1"
    const adults = numGuests || searchParams.get("guests")
    const city_latitude = searchParams.get("city_lat")
    const city_longitude = searchParams.get("city_lng")
    const ppn_bundle = searchParams.get("ppn_bundle")
    const featuredHotels = searchParams.get("hotels");
    const where = searchParams.get("where") !== null ? searchParams.get("where").replaceAll("_", " ") : ""
    const selectedHotel = hotelID || props.match.params.establishmentPricelineID;
    const decryptedHotelId = decryptID(selectedHotel)
    const promoCode = searchParams.get("PROMO-CODE" || "promo-code");
    const pricelineId = promoCode?selectedHotel: decryptedHotelId;
    const findBy = searchParams.get("findBy")
   const dispatch = useDispatch()




    //console.log("setHotelSearchState",city_id, check_in, check_out, rooms, adults, where);

  
    /* fetch destination */
    React.useEffect(() => {
        if (pricelineId !== undefined &&
            pricelineId !== null &&
            pricelineId !== "") {

            const validReq = isNumeric(pricelineId)
            if (validReq || findBy) {
                fetchHPHotelRooms(selectedHotel,findBy)
                setHotelSearchState({ city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude })
                // fetchHotelEstablishment(pricelineId, ppn_bundle, { city_id, check_in, check_out, rooms, adults })
               // fetchHotelRooms(pricelineId, ppn_bundle, { city_id, check_in, check_out, rooms, adults }, fullCurrency)
                setHotelEstablishmentId(selectedHotel)
            }



            setTimeout(() => {
                setLoaded(true)
            }, 3000);
        }
    }, [pricelineId])
    const breadcrumb = establishment.breadcrumb
    const generalInfo = establishment.generalInfo
    let padding_l = 15.5
    let padding_r = 1

    React.useEffect(()=>{
        const data = {
            hotelid_ppn: hotelID,
            check_in: check_in,
            check_out: check_out
        }

        if(hotelID && promoCode){
            dispatch(FetchCachedPullPrice(data)) ///

        }

  
    },[hotelID,promoCode])


    ///redirect if hotel not found
    React.useEffect(() => {
        const hotelId = establishment?.generalInfo?.establishmentPricelineID

        const dateString = new Date().toDateString();
        if (!preload) {
            if (!hotelId) {
                const url = "/hotel-search?city_id=" + null
                    + "&check_in=" + check_in
                    + "&check_out=" + check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + featuredHotels
                    + "&city_lat=" + city_latitude
                    + "&city_lng=" + city_longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
                // history.push(url)
            }

        }

    }, [establishment, preload])

    if (!isNaN(is_mobile)) {
        padding_l = is_mobile ? "1" : (timelineStatus ? "29.5" : "15.5")
        // padding_r = is_mobile ? "1" : (timelineStatus ? "1" : "19")
    }

    const headerHeight = (-Math.ceil(-app_menu_height / 2))
    const menuOffset = -headerHeight - 90
    const experiencesExisted = establishment && establishment.experiences && establishment.experiences.length > 0
    const neighborhoodExisted = establishment && establishment.neighborhood

    const useStyles = makeStyles({
        element: {
            border: "2px solid rgba(232, 232, 232, 1)",
            padding: "27px",
            paddingRight: "0px",
            marginTop: "25px",
        },
        elementTitle: {
            color: "rgba(0, 0, 0, 1)",
            font: "24px/28px Futura Hv BT",
            marginBottom: "20px",
        },
        elementBody: {
            color: "rgba(146, 146, 146, 1)",
            font: "16px/20px Futura Lt BT",
            marginBottom: "20px",
        }
    });

    const dateInPast = (firstDate, secondDate) =>
        firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0);

    const classes = useStyles();

    const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const reviewData = hotelReviews?.results;
    const reviewCount = hotelReviews?.data?.review_count;
    const hasReviews = reviewCount && reviewData;

    const hotelFound = generalInfo && (generalInfo?.name || generalInfo?.room_data);
    const hotelName = establishment.generalInfo !== undefined && establishment.generalInfo !== null ? establishment.generalInfo.name : ""
    const pastDate = dateInPast(new Date(moment(check_in)), new Date())


    const toggleHotelForm = () => {
        setHotelForm(!showHotelForm)
    }
    return (
        <React.Fragment>

            {is_mobile && <MobileHotelHeader toggleHotelForm={toggleHotelForm} />}
            <div style={{
                position: "relative", paddingLeft: padding_l + "%",
                paddingRight: padding_r + "%",
                paddingTop: is_mobile ? 30 : 0
            }}>
                <div style={{ maxWidth: "1200px", }}>

                    {(is_mobile ? showHotelForm : !is_mobile ? true : false) && <div style={{
                        position: "relative",
                        zIndex: "4",
                        backgroundColor: "var(--mainWhite)",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                    }}>
                        {hotelSearchForm !== undefined && hotelSearchForm.check_in !== "" &&
                            <div className="background-img-row">
                                <div className={"booking_form_container"}>
                                { <MuvHotelSearchFormEmbedded embedForm="hotel"/> }
                                    {/* <HotelSearchFormEmbedded embedForm="hotel" /> */}
                                </div>
                            </div>}

                    </div>}
                    {!is_mobile && <div style={{ font: "18px/36px Futura Lt BT", margin: "0px 10px 20px", cursor: "pointer" }} onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "5px" }} /> back to search results
                    </div>}






                    {!hotelFound && !pastDate && loaded && <div style={{ font: "24px/36px Futura Hv BT", color: "var(--lightGray)", textAlign:"center",  marginTop: 50 }}>
                     About this hotel, There are no rooms available at these dates
                    </div>}
                    {pastDate && !generalInfo?.room_data && <div style={{ font: "24px/36px Futura Hv BT", color: "var(--frameBlack)", marginTop: 10, marginBottom: 10 }}>
                        The check in date at hotel location has passed, please change your dates and try again
                    </div>}


                    {hotelFound && <React.Fragment>

                        <div style={{
                        }}>
                            { <TopBarSlider timelineStatus={timelineStatus} setMenu={setMenu} paddingLeft={Number(padding_l)} paddingRight={Number(padding_r)} />}

                            {breadcrumb !== undefined && breadcrumb.length > 0 && <Breadcrumb />}

                        </div>

                        {is_mobile && <div style={{ font: "18px/36px Futura Hv BT", margin: "20px 20px 20px", cursor: "pointer" }} >
                            {hotelName}
                        </div>}

                        <div style={{
                            position: "sticky", zIndex: "3",
                            top: is_mobile ? logo_height : headerHeight,
                        }}>
                            {!is_mobile && <Menu pointing secondary style={{ position: "relative", backgroundColor: "#FFFFFF", borderBottom: "10px solid #F8F8F8", paddingTop: "60px", }}>
                                {
                                    <Link to="rooms" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        width: is_mobile ? "90px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "rooms" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "rooms" ? "" : "pointer",
                                        borderBottom: menu === "rooms" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("rooms")}
                                    >

                                            Rooms</p></Link>
                                }
                                {
                                    <Link to="about" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        left: is_mobile ? "90px" : "200px",
                                        width: is_mobile ? "90px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "about" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "about" ? "" : "pointer",
                                        borderBottom: menu === "about" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("about")}
                                    > Area</p></Link>
                                }
                                {
                                    <Link to="amenities" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        padding: "0px 20px",
                                        left: is_mobile ? "180px" : "400px",
                                        width: is_mobile ? "90px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        color: menu === "amenities" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "amenities" ? "" : "pointer",
                                        borderBottom: menu === "amenities" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("amenities")}
                                    > Amenities</p></Link>
                                }
                                {
                                    <Link to="reviews" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width: is_mobile ? "90px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: is_mobile ? "270px" : "800px",
                                        padding: "0px 20px",
                                        color: menu === "reviews" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "reviews" ? "" : "pointer",
                                        borderBottom: menu === "reviews" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("reviews")}>Reviews</p></Link>
                                }
                                {
                                    <Link to="policies" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width: is_mobile ? "50px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "600px",
                                        padding: "0px 20px",
                                        color: menu === "policies" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "policies" ? "" : "pointer",
                                        borderBottom: menu === "policies" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("policies")}>Policies</p></Link>
                                }
                                {
                                    experiencesExisted && <Link to="experiences" spy={true} smooth={true} offset={menuOffset} duration={1000} style={{
                                        font: "18px/18px Futura Md BT",
                                        letterSpacing: "0",
                                        backgroundColor: "initial",
                                        fontWeight: "bold",
                                        opacity: "1",
                                        textAlign: "center",
                                        height: "40px",
                                        width: is_mobile ? "50px" : "200px",
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "1000px",
                                        padding: "0px 20px",
                                        color: menu === "experiences" ? "var(--mainGreen)" : "#191919",
                                        cursor: menu === "experiences" ? "" : "pointer",
                                        borderBottom: menu === "experiences" ? "10px solid var(--mainGreen)" : "10px solid #F8F8F8",
                                    }} ><p style={{
                                        verticalAlign: "middle"
                                    }} onClick={() => setMenu("experiences")}>Experiences</p></Link>
                                }
                            </Menu>}
                        </div>
                        {false && generalInfo !== undefined && generalInfo !== null && <div style={{
                            marginTop: "20px"
                        }}>

                            <EstablishmentBasicInfo />

                        </div>}

                     

                        { generalInfo !== undefined && generalInfo !== null && <div style={{
                        }}>
                            {generalInfo !== undefined && generalInfo !== null && generalInfo.room_data &&
                                <Element name="rooms" className={classes.element}>
                                    <Rooms selectedHotel={selectedHotel} />
                                </Element>}
                        </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.about !== null && generalInfo.about !== "" &&
                            generalInfo?.about && <div style={{
                            }}>
                                <Element name="about" className={classes.element}>
                                    <About />
                                </Element>
                            </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.amenities !== undefined && generalInfo.amenities !== null && generalInfo.amenities !== "" && <div style={{
                        }}>
                            <Element name="amenities" className={classes.element}>
                                <Amenities />
                            </Element>
                        </div>}

                        {generalInfo !== undefined && generalInfo !== null && generalInfo.amenities !== undefined && generalInfo.amenities !== null && generalInfo.amenities !== "" && <div style={{
                        }}>
                            <Element name="policies" className={classes.element}>
                                <Policies />
                            </Element>
                        </div>}

                        {<div style={{
                        }}>
                            <Element name="reviews" className={classes.element}>
                                <ReviewContainer />
                            </Element>
                        </div>}

                        {experiencesExisted && <div style={{
                        }}>
                            <Element name="experiences" className={classes.element}>
                                <Experiences />
                            </Element>
                        </div>}

                        {neighborhoodExisted && <div style={{
                        }}>
                            <Element name="neighborhood" className={classes.element}>
                                <Neighborhood />
                            </Element>
                        </div>}
                    </React.Fragment>}

                    <OTPRegistrationModal />
                    <div style={{ height: 50 }} />

                </div>


            </div>

        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        logo_height: state.Setting.appMenu.logo.height,
        sliderImages: state.FormBank.HotelSearch.establishment.sliderImages,
        lang: state.Setting.lang,

        timelineStatus: state.FormBank.TimelineWizard.status,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        preload: state.Setting.preload,
        establishment: state.FormBank.HotelSearch.establishment,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        //check_in: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_in]'],
        //check_out: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[check_out]'],
        //rooms: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[rooms]'],
        //adults: state.FormBank.HotelSearch.lazyLoading.requests['proxyData[adults]'],
    }
}

const mapDispatchToProps = {
    fetchHotelEstablishment: Actions.fetchHotelEstablishment,
    fetchHotelRooms: Actions.fetchHotelRooms,
    setHotelSearchState: Actions.setHotelSearchState,
    fetchGoogleAndYelpReviewsEstablishment: Actions.fetchGoogleAndYelpReviewsEstablishment,
    //fetchPhotoGalleryEstablishment: Actions.fetchPhotoGalleryEstablishment,
    fetchExperiencesEstablishment: Actions.fetchExperiencesEstablishment,
    fetchNeighborhoodEstablishment: Actions.fetchNeighborhoodEstablishment,
    fetchHPHotelRooms:Actions.fetchHPHotelRooms,

    setHotelEstablishmentId: Actions.setHotelEstablishmentId,
}

const MuvEstablishmentContainer = connect(mapStateToProps, mapDispatchToProps)(MuvEstablishmentContainer_)
export default MuvEstablishmentContainer
