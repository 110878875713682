import { BarsIcon, TimesIcon } from "../Common/SVGBank"
import * as Actions from '../../../redux/actions'
import BarsFilterTypeMenuItem from "./BarsFilterTypeMenuItem"
import StarsFilterTypeMenuItem from "./StarsFilterTypeMenuItem"
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import RangeSliderPrices from "../Common/HotelEstablishment/RangeSliderPrices"
import RangeSliderScore from "../Common/HotelEstablishment/RangeSliderScore"
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@material-ui/icons';

const BarsFilterType = connect((state) => ({
    subtypes: state.FormBank.HotelSearch.subtypes,
    isMobile: state.Setting.is_mobile,
    selectedSubtypes: state.FormBank.HotelSearch.selectedSubtypes,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    establishments: state.FormBank.HotelSearch.establishments,
    establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
    hotel_form_state: state.FormBank.HotelSearch.hotel_form_state,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    hotelSearch: state.FormBank.HotelSearch

}), {
    addSelectedSubTypeHotels: Actions.addSelectedSubTypeHotels,
    deleteSelectedSubTypeHotels: Actions.deleteSelectedSubTypeHotels,
    handleGroupSubTypeHotels: Actions.handleGroupSubTypeHotels,
})(({
    switchFeatureContainer,
    isMobile,
    subtypes,
    hotelSearch,
    selectedSubtypes,
    lazyLoading,
    addSelectedSubTypeHotels,
    deleteSelectedSubTypeHotels,
    handleGroupSubTypeHotels,
    subtypeGroup,
    establishments,
    establishmentsToFilter,
    hotel_form_state,
}) => {
    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const barsStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
            '& .MuiListItem-root': {
                boxShadow: "0 0 0 0 rgba(0,123,255,.25)",
                font: "16px/16px Futura Md BT",
                color: "#707070",
            },
        },
        buttonText: {
            color: switchFeatureContainer.color,
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            padding: "5px",
            '&:focus': {
                border: "0",
                outline: "0px",

            }
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },
        popper: {
            zIndex: "4",
            marginTop: "20px",
            font: "16px/16px Futura Md BT",
            '& .MuiListItem-root:hover': {
                backgroundColor: switchFeatureContainerColorLight,
            },
        },
        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: switchFeatureContainerColorLight
            }
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        outline: {
            '& .MuiList-root .MuiList-padding': {
                outline: "0px",
            }
        },
    }))
    const [open, setOpen] = React.useState(false)
    const anchorRef = React.useRef(null)
    const classesBars = barsStyles()
    const [specialPrices, setSpecialPrices] = React.useState(false)
    const [resortFee, setResortFee] = React.useState(false)

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const SMOPTYPE = {
            
        id:"n-"+"SMOP",
        type:"smopRate",
        title: "Special Prices",
       }

       const RESORTTYPE = {
        title: "No resort fee",
        id: "n-"+"resortFees",  
        type: "resortFees" ,
       }
    const handleSpecialPrices = () => {
        filterCheckbox(SMOPTYPE)
        setSpecialPrices(!specialPrices)
    }

    const handleResortFee = () => {
        filterCheckbox(RESORTTYPE)
        setResortFee(!resortFee)
       
    }

    const filterCheckbox = (subtype)=>{

        selectedSubtypes.find(val => val.id === subtype.id) ?
        deleteSelectedSubTypeHotels(subtype, selectedSubtypes, establishments, hotel_form_state, lazyLoading)
        :
        handleAddSelectedSubTypeHotels(establishmentsToFilter, subtype, lazyLoading, selectedSubtypes)

    }

    const handleAddSelectedSubTypeHotels = (establishmentsToFilter, subtype, lazyLoading, selectedSubtypes) => {
        addSelectedSubTypeHotels(establishmentsToFilter, subtype, selectedSubtypes, hotel_form_state, lazyLoading)

    }



    const prevOpen = React.useRef(open)
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])
    return (
        <div style={{
            paddingTop: "20px", zIndex: "1", marginRight: "5px"
        }}>
            <div
                style={{
                    display: "inline-flex",
                    cursor: "pointer"
                }}
                onClick={handleToggle}
                ref={anchorRef}
                aria-haspopup="true">
                <BarsIcon />
            </div>
            {open && <Grow
                in={open}
                style={{ transformOrigin: '0 0 0' }}
                {...(open ? { timeout: 1000 } : {})}
            ><div style={{ position: "absolute", zIndex: "5", top: "56px", backgroundColor: "var(--mainWhite)", padding: "10px 0", }}>
                    <div style={{ display: "flex", width: "270px", paddingLeft: "10px", paddingRight: "10px", outline: "0", }} >
                        <div style={{ flexGrow: "1", outline: "0", font: "18px/18px Futura Hv BT", }}>Filters</div>
                        <div onClick={(event) => handleClose(event)}>{isMobile ? <TimesIcon width={13} height={13} /> : <TimesIcon width={20} height={20} />}</div>
                    </div>
                </div></Grow>}
            <ClickAwayListener onClickAway={handleClose}>
                <Popper open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    className={classesBars.popper}
                    placement={"bottom-start"}
                    transition
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }}
                    disablePortal
                    style={{ overflowY: "scroll", overflowX: "hidden", marginBottom: "8px", maxHeight: "500px", backgroundColor: "#fff" }}
                >
                    <Grow
                        in={open}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(open ? { timeout: 1000 } : {})}
                    >
                        <Paper>
                            <MenuList style={{ outline: "0px", width: "270px", height: "100%", marginTop: "30px" }}>
                                <RangeSliderPrices establishments={hotelSearch.establishments} loVal={hotelSearch.lowPrice} hiVal={hotelSearch.highPrice} />
                                <RangeSliderScore establishments={hotelSearch.establishments} loVal={hotelSearch.lowGuestScore} hiVal={hotelSearch.highGuestScore} />
                                
                                <div style={{padding:10, paddingBottom:0}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedSubtypes.find(val => val.id === SMOPTYPE?.id )}
                                                onChange={handleSpecialPrices}
                                                icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                                                checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                                                name={"Müver special prices"}
                                            />
                                        }
                                        style={{ display: 'block', marginBottom: -5 }}
                                        label={<span style={{  font: "16px/16px Futura Md BT" }}>
                                            Müver special prices
                                        </span>}
                                    />
                                </div>

                                <div style={{padding:10, paddingTop:0}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                            checked={selectedSubtypes.find(val => val.id === RESORTTYPE?.id )}
                                                onChange={handleResortFee}
                                                icon={<CheckBoxOutlineBlankOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                                                checkedIcon={<CheckBoxOutlined style={{ font: '22px/22px Futura Hv BT', marginTop: -5, color: '#19BC9B' }} />}
                                                name={"No resort fee"}
                                            />
                                        }
                                        style={{ display: 'block', marginBottom: -5 }}
                                        label={<span style={{  font: "16px/16px Futura Md BT" }}>
                                           No resort fee
                                        </span>}
                                    />
                                </div>
                                
                                <StarsFilterTypeMenuItem />

                                

                                {subtypes !== undefined
                                    && subtypes.length > 0
                                    && subtypes.map((subtypeGroup, subtype_group_index) => {
                                        return (
                                            <BarsFilterTypeMenuItem subtypeGroup={subtypeGroup} key={subtype_group_index} />
                                        )
                                    })}
                            </MenuList>
                        </Paper>
                    </Grow>
                </Popper>
            </ClickAwayListener>
        </div >

    )
})
export default BarsFilterType
