import React from 'react'
import "./ReviewsUserRating.css"

import { connect, useSelector } from 'react-redux'


String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

const ReviewsUserRating_ = ({generalInfo}) => {
  
    const hotelReviews = useSelector(state => state.HotelBooking.reviews);
    const reviewData = hotelReviews?.results;
    const reviewCount = hotelReviews?.data?.review_count;
    const hasReviews = reviewCount && reviewData;


    //if(hasReviews) console.log("ReviewsUserRating_" , generalInfo.guest_score_categories, generalInfo.reviewsAverage, generalInfo.reviewsDescription, generalInfo.star_rating, generalInfo.rating_count, generalInfo.review_count);
    
    const greenLine = ((length, label="")=> {
    
        return (<div style={{height: "45px"}}>
            <div style={{height: "20px", font: "12px/18px Futura Md BT", color: "#929292", marginBottom: "5px"}}>
                {label}
            </div>
            <div style={{height: "10px"}}>
                <hr style={{position:"absolute", margin: "0px", width: 300, backgroundColor: "lightgrey", height: "8px", float: "left", borderRadius: "7px" }}/>
                <hr style={{position:"absolute", margin: "0px", width: length, backgroundColor: "var(--mainGreen)", height: "8px", float: "left" , borderRadius: "7px"}}/>
            </div>
        </div>) }
    );

    

    const categories =generalInfo?.guest_score_categories ? Object?.entries(generalInfo?.guest_score_categories)?.map(([key, value=0]) => {
        return (<div key={key}>{greenLine(value*30, key.toProperCase()+" "+(value??0))}</div>)
    }):null

    return (
        <React.Fragment>
              
             <div className="reviewsUserRating">
                {hasReviews &&  <div className="header">                  
                        <table>
                        <tbody>
                            <tr style={{width: "240px"}}>
                                <th style={{width: "100px", font: "60px/72px Futura Lt BT", color: "#001A46"}}>{generalInfo &&generalInfo.reviewsAverage  ? generalInfo.reviewsAverage.toFixed(1): ''}</th>
                                <th style={{width: "130px", paddingLeft:10,}}>
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td style={{font: "18px/22px Futura Md BT", color: "#00185B", marginLeft:"10px"}}>{generalInfo.reviewsDescription}</td>
                                        </tr>
                                        <tr> 
                                             <td style={{font: "12px/22px Futura Md BT", color: "#00185B", marginLeft:"10px"}}>{reviewCount + " reviews"}</td> 
                                        </tr> 
                                        </tbody>
                                    </table>
                                </th>
                            </tr>
                        </tbody>
                        </table>
                </div> } 
                {/* 3 = 300/100 */}           
                {hasReviews && generalInfo.guest_score_categories!==undefined && generalInfo.guest_score_categories!==null && <div className="barScale">
                    {categories}
                </div>}
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => ({
    //generalInfo: state.FormBank.HotelSearch.establishment.generalInfo
    
})

const mapDispatchToProps = {
}

const ReviewsUserRating = connect(mapStateToProps, mapDispatchToProps)(ReviewsUserRating_)
export default ReviewsUserRating
