import AlertBarsReducer from './AlertBarsReducer'
import DestinationReducer from './DestinationReducer';
import DisplayVideoReducer from './DisplayVideoReducer';
import FeatureReducer from './FeatureReducer';
import FormBankReducer from './FormBankReducer';
import GetMediaReducer from './GetMediaReducer';
import HomeReducer from './HomeReducer';
import IsShowReducer from './IsShowReducer';
import LandingReducer from './LandingReducer';
import MemberActionsReducer from './MemberActionsReducer';
import MemberReducer from './MemberReducer';
import PublicTripReducer from './PublicTripReducer';
import SettingReducer from './SettingReducer';
import SocialReducer from './SocialReducer';
import { combineReducers } from 'redux';
import { PersonalizedTripReducer } from '../../Components/WebContent/FeaturePage/personalizedTrip/personalizedTrip.slice';
import { FlightsReducer } from '../../Components/WebContent/FlightsPage/services/Flights.slice';
import { ActiveTripReducer } from '../../Components/WebContent/Common/Trip/ActiveTrip.slice';
import { HotelBookingReducer } from '../../Components/WebContent/BookingPage/PaymentPage/payment.slice';
import { DestinationSliceReducer } from '../../Components/WebContent/FeaturePage/Destination.slice';
import { SocialPageReducer } from '../../Components/WebContent/SocialPage/social.slice';
import { LoginSliceReducer } from '../slices/Login.slice';
import { AiChatBoxReducer } from '../../Components/WebContent/Common/AiChatBox/AiChatBoxReducer';


const allReducer = combineReducers({
    Setting: SettingReducer,
    Member: MemberReducer,
    MemberActions: MemberActionsReducer,
    Home : HomeReducer,
    Landing: LandingReducer,
    Destination : DestinationReducer,
    Feature: FeatureReducer,
    PublicTrip: PublicTripReducer,
    Social: SocialReducer,
    FormBank : FormBankReducer,
    DisplayVideo: DisplayVideoReducer,
    GetMedia: GetMediaReducer,
    AlertBars : AlertBarsReducer,
    IsShow: IsShowReducer,
    PersonalizedTrip: PersonalizedTripReducer,
    Flights: FlightsReducer,
    ActiveTrip: ActiveTripReducer,
    HotelBooking: HotelBookingReducer,
    DestinationSlice: DestinationSliceReducer,
    SocialPage: SocialPageReducer,
    LoginPage: LoginSliceReducer,
    AiChatBox: AiChatBoxReducer
});

export default allReducer;

/* each reducer must be add to combine */