export const formatSearchBoxString = (data) => {
  if (!data || typeof data !== "object") return "Invalid Data";

  const { type, city, country, hotel_name, address, where } = data;

  if (type === "hotel") {
    const hotel = hotel_name || "Unnamed Hotel"; // Fallback if no hotel name
    const cityName = address?.city_name || city || "Unknown City";
    const countryCode = address?.country_code ? `, ${address.country_code}` : "";

    return `${hotel}, ${cityName}${countryCode}`;
  }

  if (type === "city") {
    const cityName = city || "Unknown City";
    return country ? `${cityName}, ${country}` : cityName;
  }

  return where ? where : "Unknown Location";
};

export function formatLocationLabel(option, isHotelSearch = false) {
  try {
    const city = option?.city || "";
    const state = option?.state || "";
    const country = option?.country || "";
    const countryCode = option?.country_code || "";
    const hotelName = option?.hotel_name || "";
    const where = option?.where || ""
    let val = "";
    if (hotelName) {
      val = `${hotelName} - ${city}${countryCode ? `, ${countryCode}` : ""}`;
    }

    if (isHotelSearch && !hotelName) {
      val = city;
    }

    if (city && state && country) {
      val = `${city}, ${state}, ${country}`;
    }

    if (city && country) {
      val = `${city}, ${country}`;
    }
    if (city && state && country) {
      val = `${city}, ${state}, ${country}`;
    }

    if (city && state) {
      val = `${city}, ${state}`;
    }

    if (where) {
      val = where
    }


    return val;
  } catch (error) {
    console.error("Error formatting location label:", error);
    return "";
  }
}


export function calculateDateDuration(checkIn, checkOut) {
  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);

  // Calculate difference in milliseconds
  const diffTime = checkOutDate - checkInDate;

  // Convert milliseconds to days
  const durationDays = diffTime / (1000 * 60 * 60 * 24);

  return durationDays;
}