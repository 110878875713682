import * as Actions from '../../../../redux/actions'
import { URL_REPLACE } from "../RegExValidate"
import { groupBy } from 'lodash';
import {
    AddressMapIcon,
    CalendarDuotoneIcon,
    SearchIcon,
    TimesIcon,
} from "../SVGBank"

import {
    AsyncTypeahead, Menu, Highlighter,
    MenuItem,
} from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useHistory, useLocation } from "react-router-dom"
import './booking.css'
import { encryptID } from '../../HotelPage/hotelServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { calculateDateDuration, formatSearchBoxString } from './bookingFormHelper';


export const HotelOnlyFormEmbedded_ = ({ establishment, lang, uuid, is_mobile,
    hotelSearchForm, setHotelSearchForm, }) => {

    const history = useHistory()

    const location = useLocation()
    const [checkInDate, setCheckInDate] = React.useState(hotelSearchForm.check_in ? moment.utc(hotelSearchForm.check_in).toDate() : "")
    const [checkOutDate, setCheckOutDate] = React.useState(hotelSearchForm.check_out ? moment.utc(hotelSearchForm.check_out).toDate() : "")


    // console.log(checkInDate,hotelSearchForm.check_in,222)

    const [defaultCurrency, setDefaultCurrency] = React.useState(null);
    const [selectedCityInfo, setSelectedCityInfo] = React.useState(null);


    const disableButton = (!selectedCityInfo?.city_id && !hotelSearchForm.city_id) || !checkInDate || !checkOutDate
    var searchParams = new URLSearchParams(document.location.search.substring(1));

    const prefillEstablishment = establishment;


    React.useEffect(() => {
        if (prefillEstablishment && prefillEstablishment && prefillEstablishment?.generalInfo?.establishmentPricelineID) {
            const selectedCity =
            {
                cityid_ppn: prefillEstablishment?.generalInfo?.city_id || prefillEstablishment?.generalInfo?.room_data?.city.id,
                city: prefillEstablishment?.generalInfo?.cityName,
                city_id: prefillEstablishment?.generalInfo?.city_id || prefillEstablishment?.generalInfo?.room_data?.city.id,
                country: prefillEstablishment?.generalInfo?.countryName,
                latitude: prefillEstablishment?.generalInfo?.latitude,
                longitude: prefillEstablishment?.generalInfo?.longitude,
                state: prefillEstablishment?.generalInfo?.stateName ?? "",
                //  hotel_name: prefillEstablishment?.generalInfo?.name,
                address: {
                    city_name: prefillEstablishment?.generalInfo?.cityName,
                    country_code: prefillEstablishment?.generalInfo?.countryCode
                },
                hotelid_ppn: prefillEstablishment?.generalInfo?.establishmentPricelineID
            }


            setSelectedCityInfo(selectedCity)

        }
    }, [prefillEstablishment]) ///prefill only on first load



    React.useEffect(() => {   //updatehotel info
        if (hotelSearchForm && hotelSearchForm.city_id) {
            const selectedCity = {
                cityid_ppn: hotelSearchForm.city_id,
                city_id: hotelSearchForm?.city_id,
                city: hotelSearchForm.where.split(",")[0],
                country: hotelSearchForm.where.slice(hotelSearchForm.where.split(", ")[0].length + 2),
                latitude: hotelSearchForm.city_latitude,
                longitude: hotelSearchForm.city_longitude,
                hotel_name: hotelSearchForm?.hotel_name,
                address: {
                    city_name: hotelSearchForm?.address?.city_name,
                    country_code: hotelSearchForm?.address?.country_code
                },
                where: hotelSearchForm?.where,
                type: hotelSearchForm?.type,
                hotelid_ppn: hotelSearchForm?.hotelid_ppn
            }

            setSelectedCityInfo(selectedCity)
        }
    }, [hotelSearchForm])



    const handleChangeCheckInDate = (date) => {
        setCheckInDate(date)
        let hotel_form_state = { ...hotelSearchForm };
        console.log(hotel_form_state);
        hotel_form_state.check_in = moment(date).format("YYYY-MM-DD");
        hotel_form_state.isNew = true;

        const duration = !hotelSearchForm?.check_out ? 1 : calculateDateDuration(hotelSearchForm?.check_in, hotelSearchForm?.check_out)
        hotel_form_state.check_out = moment(date).add(duration, 'days').format("YYYY-MM-DD"); // Preselect with one day added
        setCheckOutDate(moment(date).add(duration, 'days').toDate());


        setHotelSearchForm(hotel_form_state)

        //setCitiesData(city.destinationId, date, city.duration, city.positionId, city.availableSelectDate)
    }
    const handleChangeCheckOutDate = (date) => {
        setCheckOutDate(date)
        let hotel_form_state = { ...hotelSearchForm };
        console.log(hotel_form_state);
        hotel_form_state.check_out = moment(date).format("YYYY-MM-DD");
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }
    const handleRoomsChange = (e) => {
        let hotel_form_state = { ...hotelSearchForm };
        console.log(hotel_form_state);
        hotel_form_state.rooms = e.target.value;
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }
    const handleGuestsChange = (e) => {
        let hotel_form_state = { ...hotelSearchForm };
        console.log(hotel_form_state);
        hotel_form_state.adults = e.target.value;
        hotel_form_state.isNew = true;

        setHotelSearchForm(hotel_form_state);
    }


    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";



    React.useEffect(() => {
        if (hotelSearchForm.city_id !== "") {
            console.log("setCheckInDate", hotelSearchForm.check_in, { selectedCityInfo })

            setCheckInDate(new Date(moment(hotelSearchForm.check_in)))
            setCheckOutDate(new Date(moment(hotelSearchForm.check_out)))

            //console.log("selectedCity",hotelSearchForm.check_in,{selectedCity})
        }
    }, [hotelSearchForm.check_in, hotelSearchForm.check_out, hotelSearchForm.city_id, hotelSearchForm.isSearching])

    React.useEffect(() => {
        if (selectedCurrency && defaultCurrency) {
            handleHotelSearchForm()
        }
    }, [selectedCurrency])



    React.useEffect(() => {
        setDefaultCurrency(selectedCurrency)
    }, [])




    const handleHotelSearchForm = () => {
        let hotel_form_state = { ...hotelSearchForm }
        if (selectedCityInfo?.city_id) {
            hotel_form_state = { ...hotelSearchForm, ...selectedCityInfo }
        }

        // if query and dates are entered, do search

        const rooms = (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
        const guests = (hotel_form_state.adults ? hotel_form_state.adults : 2)
        const obj = {
            rooms: hotel_form_state.rooms,
            adults: hotel_form_state.adults,
            isNew: true,
            isSearching: true,
        }


        hotel_form_state = Object.assign(hotel_form_state, obj)

        if (hotel_form_state.check_in !== "" && hotel_form_state.check_out !== "") {
            //console.log({hotel_form_state,embedForm,bookingForm});


            setHotelSearchForm(hotel_form_state);
            var searchParams = new URLSearchParams(document.location.search.substring(1));
            const promoCode = searchParams.get("promo-code") || searchParams.get("PROMO-CODE");
            const name = establishment?.generalInfo?.name;
            const hotelId = establishment?.generalInfo?.establishmentPricelineID;
            const encryptedId = encryptID(hotelId)
            const where = formatSearchBoxString(selectedCityInfo)

            const city = hotel_form_state?.city ?? ""
            const state = hotel_form_state?.state ?? ""
            const country = hotel_form_state?.country ?? ""



            if (promoCode) {

                history.push("/hotel-" + name?.replace(URL_REPLACE, "_") + city + state + country + "-" + hotelId
                    + "?city_id=" + hotel_form_state.city_id
                    + "&check_in=" + hotel_form_state.check_in
                    + "&check_out=" + hotel_form_state.check_out
                    + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
                    + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
                    + "&city_lat=" + (hotel_form_state.city_latitude)
                    + "&city_lng=" + (hotel_form_state.city_longitude)
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
                    + "&promo-code=" + (promoCode))
            }
            else {

                history.push("/hotel-" + name?.replace(URL_REPLACE, "_") + city + state + country + "-" + encryptedId
                    + "?city_id=" + hotel_form_state.city_id
                    + "&check_in=" + hotel_form_state.check_in
                    + "&check_out=" + hotel_form_state.check_out
                    + "&rooms=" + (hotel_form_state.rooms ? hotel_form_state.rooms : 1)
                    + "&guests=" + (hotel_form_state.adults ? hotel_form_state.adults : 1)
                    + "&city_lat=" + (hotel_form_state.city_latitude)
                    + "&city_lng=" + (hotel_form_state.city_longitude)
                    + "&where=" + (where.replace(URL_REPLACE, "_")))
            }





        }

    }



    // const where = hotel_form_state?.where.replace(URL_REPLACE, "_");

    const isHotelSearch = hotelSearchForm?.featuredHotels && hotelSearchForm?.featuredHotels.length > 0;

    return (
        <React.Fragment>
            <div className="row m-0 p-0">
                <div className="col-12 col-md-11 p-0">
                    <div className="row m-0 p-0 ">


                        <div className="col-6 col-md-3 mt-3 p-0 hotelDates">
                            <div className="position-relative" >
                                <DatePicker
                                    selected={checkInDate}
                                    onChange={date => handleChangeCheckInDate(date)}
                                    placeholderText="Check-In date"
                                    dateFormat="yyyy/MM/dd"
                                    minDate={new Date()}
                                    // maxDate={new Date(checkOutDate)}
                                    className="form-control" />
                                <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                    <CalendarDuotoneIcon width={15} height={15} />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-3 mt-3 p-0 hotelDates">
                            <div className="position-relative" >
                                <DatePicker
                                    selected={checkOutDate}
                                    minDate={checkInDate !== '' ? new Date(checkInDate).getTime() + 86400000 : new Date()}
                                    maxDate={checkInDate !== '' ? new Date(new Date(checkInDate).getTime() + 21 * 86400000) : new Date(new Date().getTime() + 21 * 86400000)}
                                    dateFormat="yyyy/MM/dd"
                                    onChange={date => handleChangeCheckOutDate(date)}
                                    placeholderText="Check-Out date"
                                    className="form-control" />
                                <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                                    <CalendarDuotoneIcon width={15} height={15} />
                                </div>
                            </div>
                        </div>


                        <div className="col-6 col-md-3 mt-3 p-0">
                            <select className="form-control pl-3" id="rooms" placeholder="Rooms"
                                value={hotelSearchForm.rooms != "" ? hotelSearchForm.rooms : "1"}
                                onChange={e => handleRoomsChange(e)} >
                                <option value="placeholder" disabled>Rooms</option>
                                <option value="1">1 Room</option>
                                <option value="2">2 Rooms</option>
                                <option value="3">3 Rooms</option>
                                <option value="4">4 Rooms</option>
                                <option value="5">5 Rooms</option>
                                <option value="6">6 Rooms</option>
                                <option value="7">7 Rooms</option>
                                <option value="8">8 Rooms</option>
                                <option value="9">9 Rooms</option>
                            </select>
                        </div>
                        <div className="col-6 col-md-3 mt-3 p-0">
                            <select className="form-control pl-3" id="guests" placeholder="Guests"
                                value={hotelSearchForm.adults != "" ? hotelSearchForm.adults : "2"}
                                onChange={e => handleGuestsChange(e)} >
                                <option value="placeholder" disabled>Guests</option>
                                <option value="1">1 Guest</option>
                                <option value="2">2 Guests</option>
                                <option value="3">3 Guests</option>
                                <option value="4">4 Guests</option>
                                <option value="5">5 Guests</option>
                                <option value="6">6 Guests</option>
                                <option value="7">7 Guests</option>
                                <option value="8">8 Guests</option>
                                <option value="9">9 Guests</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-1 p-0">
                    <div className="row m-0 p-0">
                        <div className="col-12 p-0">
                            <button disabled={disableButton} className="btn btn-default submitBtn mt-3 p-0"
                                onClick={() => handleHotelSearchForm()}>
                                <FontAwesomeIcon size='mg' icon={faSearch} style={{ color: "white" }} />

                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )

}


const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    switch_container: state.Member.switch_container,
    searchListCities: state.Home.search_list_cities,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    bookingForm: state.FormBank.BookingForm,
    establishment: state.FormBank.HotelSearch.establishment,
})

const mapDispatchToProps = {
    //createMultipleDestinationTrip: Actions.createMultipleDestinationTrip,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setSwitchContainer: Actions.setSwitchContainer,

    getCitiesList: Actions.getPriceLineCitiesList,      // HomeAction.js, PL_FETCH_CITIES_LIST_SUCCESS
    //getCitiesList: Actions.getCitiesList,               // HomeAction.js, FETCH_CITIES_LIST_SUCCESS
    getAirportList: Actions.getPriceLineAirportList,    // HomeReducer.js

    setHotelSearchForm: Actions.setHotelSearchForm,     // FormBankActions, FormBankReducer    
    getPriceLineHotels: Actions.getPriceLineHotels,

}

const HotelOnlyFormEmbedded = connect(mapStateToProps, mapDispatchToProps)(HotelOnlyFormEmbedded_)
export default HotelOnlyFormEmbedded
